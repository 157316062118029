import * as Sentry from '@sentry/browser';

import {
  CLEAR_CURRENT_PROJECT_ID,
  CLEAR_CURRENT_TIMELINE_EVENTS_BEING_CREATED,
  CLEAR_CURRENT_TIMELINE_EVENT_BEING_CREATED,
  CLEAR_CURRENT_TIMELINE_IS_READY,
  CLEAR_SIGN_IN_PROMISE,
  SET_404_ROUTE_ERROR,
  SET_CURRENT_PROJECT_ID,
  SET_CURRENT_TIMELINE_ANALYTICS,
  SET_CURRENT_TIMELINE_EVENT_BEING_CREATED,
  SET_CURRENT_TIMELINE_ID,
  SET_CURRENT_TIMELINE_IS_PUBLISHED,
  SET_CURRENT_TIMELINE_IS_READY,
  SET_CURRENT_USER_ROLE,
  SET_CURRENT_WORKSPACE_ID,
  SET_CURRENT_WORKSPACE_ROLE,
  SET_NARRATIVE_EVENT,
  SET_SIGNED_IN,
  SET_SIGN_IN_PROMISE,
  SET_SIMULATION_BEAT_MACHINE_COMPLETED,
  SET_USER_TOKEN_WORKSPACE_IDS,
} from '@/store/mutation-types';

import {
  sign_in,
  sign_out,
  refresh_user_token,
  set_custom_claims,
} from '@/services/firebase-auth';

import bootstrap_data_vuex_plugin from '@/services/bootstrap-data-vuex-plugin';

import {
  doc,
  getDoc,
  runTransaction,
  writeBatch,
  deleteDoc,
  increment,
  deleteField,
  arrayRemove,
  arrayUnion,
} from 'firebase/firestore';

import {
  bind_collection,
  bind_document,
  unbind_collection,
  unbind_document,
} from 'shared-js/firestore-vuex-bindings';

import {
  api_key_refs,
  timeline_beat_refs,
  timeline_event_reference_refs,
  timeline_row_refs,
  timeline_snapshot_refs,
  timeline_event_refs,
  timeline_event_link_refs,
  project_refs,
} from '@/services/firestore/references';

import {
  db,
} from '@/services/firebase';

import {
  publish as send_publish_timeline_pub_sub_message,
} from '@/services/api/publish-timeline';

import {
  project_created as send_project_created_pub_sub_message,
} from '@/services/api/project-created';

import {
  get as get_analytics,
} from '@/services/api/analytics';

import {
  create as create_workspace_membership,
  update as update_workspace_membership,
  delete_resource as delete_workspace_membership,
} from '@/services/api/workspace-memberships';

import {
  update_firestore_document,
  create_firestore_document,
  add_firestore_document,
} from '@/services/firestore_utils';

import {
  timeline_event_reference_directions_map,
  timeline_event_status_map,
  timeline_publish_sync_status_map,
  timeline_status_map,
} from '@/services/constants';

import {
  sequenced_timeline_child_collections,
} from 'shared-js/constants/firestore-structures';

import {
  initial_timeline_row_name,
} from '@/services/template-string';

import {
  content_search,
} from '@/services/content-search';

import {
  content_providers_config,
} from '@/services/content-providers-config';


import {
  create as create_api_key,
  delete_resource as delete_project_api_key,
} from '@/services/api/project-api-keys';

import {
  create_composite_id,
  split_composite_id,
} from 'shared-js/create-composite-id';

import {
  fetch_timeline_from_firestore,
} from '@/services/fetch-timeline-from-firestore';

import {
  write_timeline_to_firestore,
} from '@/services/write-timeline-to-firestore';

import {
  logger,
} from '@/services/logger';

import {
  timeline_events_with_multiple_links,
} from '@/services/timeline-events-with-links';

import {
  regenerate_threads,
} from '@/services/regenerate_threads';

import {
  rule_fields_defaults,
} from '@/services/condition-rules';

import {
  convert_openai_schema_to_timeline,
} from '@/services/openai-utils';

/**
 * @typedef {import('@/store/types').ActionContext} ActionContext
 */

const actions = {};

// Firestore bindings.

/**
 * @param {ActionContext} context
 */
actions.bind_user = (context) => {
  let has_resolved = false;
  return new Promise((resolve, reject) => {
    bind_document({
      state_prop: 'user',
      document_ref: doc(context.getters.firestore_refs.must_users, context.state.user_id),
      commit: context.commit,
      on_change: async (user_data) => {
        const workspace_ids_match = () => {
          return [
            'ws_owner_ids',
            'ws_editor_ids',
            'ws_viewer_ids',
          ].reduce((acc, prop) => {
            if (!acc) {
              return false;
            }
            if (!user_data[prop] || user_data[prop].length !== context.state.user_token_workspace_ids[prop].length) {
              return false;
            }
            return !user_data[prop].find((id) => {
              return !context.state.user_token_workspace_ids[prop].includes(id);
            });
          }, true);
        };
        if (!workspace_ids_match()) {
          await set_custom_claims();
          await context.dispatch('update_custom_claims');
        }
        if (!has_resolved) {
          has_resolved = true;
          resolve(user_data);
        }
      },
    })
      .catch(reject);
  });
};

/**
 * @param {ActionContext} context
 */
actions.unbind_user = (context) => {
  unbind_document({
    state_prop: 'user',
    commit: context.commit,
  });
};

/**
 * @param {ActionContext} context
 */
actions.bind_workspaces = (context) => {
  if (!context.getters.firestore_refs.user_workspaces) {
    throw new Error(`No workspaces found for user: ${context.state.user_id}`);
  }
  return bind_collection({
    state_prop: 'workspaces',
    collection_ref: context.getters.firestore_refs.user_workspaces,
    commit: context.commit,
    is_map: true,
  });
};

/**
 * @param {ActionContext} context
 */
actions.bind_current_workspace_references = (context) => {
  if (!context.getters.firestore_refs.current.workspace || !context.getters.firestore_refs.workspace_members || !context.getters.firestore_refs.projects) {
    return;
  }
  return Promise.all([
    bind_document({
      state_prop: 'current_workspace',
      document_ref: context.getters.firestore_refs.current.workspace,
      commit: context.commit,
    }),
    bind_collection({
      state_prop: 'workspace_members',
      collection_ref: context.getters.firestore_refs.workspace_members,
      commit: context.commit,
      is_map: true,
    }),
    bind_collection({
      state_prop: 'projects',
      collection_ref: context.getters.firestore_refs.projects,
      commit: context.commit,
      is_map: true,
    }),
  ]);
};

/**
 * @param {ActionContext} context
 */
actions.bind_current_timeline_references = (context) => {

  /**
   * @type { {[key in keyof TimelineChildCollections]: string} }
   */
  const child_collection_to_state_properties = {
    timeline_rows: 'current_timeline_rows',
    timeline_beats: 'current_timeline_beats',
    timeline_events: 'current_timeline_events',
    timeline_linked_threads: 'current_timeline_linked_threads',
    timeline_event_links: 'current_timeline_event_links',
    timeline_progression_conditions: 'current_timeline_progression_conditions',
    timeline_push_events: 'current_timeline_push_events',
  };

  if (!context.getters.firestore_refs.current.timeline || !context.getters.firestore_refs.current.timeline_event_references) {
    return;
  }

  const promises = [

    // Bind the timeline itself.
    bind_document({
      state_prop: 'current_timeline',
      document_ref: context.getters.firestore_refs.current.timeline,
      commit: context.commit,
    }),

    // Bind the timeline_event_references_document.
    bind_document({
      state_prop: 'current_timeline_event_references',
      document_ref: context.getters.firestore_refs.current.timeline_event_references,
      commit: context.commit,
    }),

    // Bind all the timeline child collections.
    ...sequenced_timeline_child_collections.map((collection_name) => {
      if (!child_collection_to_state_properties[collection_name]) {
        throw new Error(`No vuex state property found for timeline child collection ${collection_name}`);
      }
      const state_property = child_collection_to_state_properties[collection_name];
      const firestore_ref = context.getters.firestore_refs[collection_name];
      if (!firestore_ref) {
        throw new Error(`No firestore ref found for timeline child collection ${collection_name}`);
      }
      return bind_collection({
        state_prop: state_property,
        collection_ref: firestore_ref,
        commit: context.commit,
        is_map: true,
      });
    }),
  ];

  if (context.state.current_timeline_is_published) {
    promises.push(
      bind_collection({
        state_prop: 'current_sequenced_timeline_snapshots',
        collection_ref: context.getters.firestore_refs.sequenced_timeline_snapshots,
        commit: context.commit,
        is_map: true,
      })
    );
  }

  return Promise.all(promises);
};

/**
 * @param {ActionContext} context
 */
actions.unbind_current_timeline_references = (context) => {
  unbind_document({
    state_prop: 'current_timeline',
    commit: context.commit,
  });
  unbind_collection({
    state_prop: [
      'current_timeline_rows',
      'current_timeline_beats',
      'current_timeline_events',
      'current_timeline_event_links',
      'current_timeline_progression_conditions',
      'current_timeline_event_references',
      'current_timeline_push_events',
      'current_sequenced_timeline_snapshots',
    ],
    commit: context.commit,
  });
};

/**
 * @param {ActionContext} context
 */
actions.bind_current_project_references = (context) => {
  if (!context.getters.firestore_refs.current.project || !context.getters.firestore_refs.timelines || !context.getters.firestore_refs.published_timelines || !context.getters.firestore_refs.narrative_events || !context.getters.firestore_refs.hook_contents || !context.getters.firestore_refs.api_keys || !context.getters.firestore_refs.webhook_secrets) {
    return;
  }
  return Promise.all([
    bind_document({
      state_prop: 'current_project',
      document_ref: context.getters.firestore_refs.current.project,
      commit: context.commit,
    }),
    bind_collection({
      state_prop: 'project_timelines',
      collection_ref: context.getters.firestore_refs.timelines,
      commit: context.commit,
      is_map: true,
    }),
    bind_collection({
      state_prop: 'project_published_timelines',
      collection_ref: context.getters.firestore_refs.published_timelines,
      commit: context.commit,
      is_map: true,
    }),
    bind_collection({
      state_prop: 'project_narrative_events',
      collection_ref: context.getters.firestore_refs.narrative_events,
      commit: context.commit,
      is_map: true,
    }),
    bind_collection({
      state_prop: 'project_hook_contents',
      collection_ref: context.getters.firestore_refs.hook_contents,
      commit: context.commit,
      is_map: true,
    }),
    bind_collection({
      state_prop: 'project_api_keys',
      collection_ref: context.getters.firestore_refs.api_keys,
      commit: context.commit,
      is_map: true,
    }),
    bind_collection({
      state_prop: 'project_webhook_secrets',
      collection_ref: context.getters.firestore_refs.webhook_secrets,
      commit: context.commit,
      is_map: true,
    }),
  ]);
};

/**
 * @param {ActionContext} context
 */
actions.unbind_current_project_references = (context) => {
  unbind_document({
    state_prop: 'current_project',
    commit: context.commit,
  });
  unbind_collection({
    state_prop: [
      'project_timelines',
      'project_timelines',
      'project_published_timelines',
      'project_narrative_events',
      'project_hook_contents',
      'project_api_keys',
      'project_webhook_secrets',
    ],
    commit: context.commit,
  });
};

// End firestore bindings.


/**
 * @param {ActionContext} context
 */
actions.update_custom_claims = async (context) => {
  const token = await refresh_user_token();
  context.commit(SET_USER_TOKEN_WORKSPACE_IDS, {
    ws_owner_ids: token.claims.ws_owner_ids,
    ws_editor_ids: token.claims.ws_editor_ids,
    ws_viewer_ids: token.claims.ws_viewer_ids,
  });
  context.commit(SET_CURRENT_WORKSPACE_ROLE);
  context.commit(SET_CURRENT_USER_ROLE, token.claims.role);
  await context.dispatch('bind_workspaces');
};

/**
 * Moves a timeline event (with ID event_id) to a new step and/or row index.
 * Also handles inserting a new row and/or column.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {string} param2.event_id
 * @param {number} param2.new_step_index
 * @param {number} param2.new_row_index
 * @param {boolean} param2.insert_column
 * @param {boolean} param2.insert_row
 * @returns {Promise}
 */
actions.move_current_timeline_event = async (context, {
  event_id,
  new_step_index,
  new_row_index,
  insert_column,
  insert_row,
}) => {

  // We're moving an event to a new step_index and/or row_index. We may also be inserting a new
  // column or row. If the row_index we're moving to does not already have a row, we must create one.
  // If the row we're moving from has no remaining events and has not been edited, we delete it.

  const timeline_ref = context.getters.firestore_refs.current.timeline;
  const current_timeline = context.state.current_timeline;

  if (!current_timeline || !context.getters.firestore_refs.timeline_rows) {
    return;
  }

  const batch = writeBatch(db);

  // We'll store all timeline document changes here and persist them at the end.
  const timeline_changes = {};

  if (insert_column) {

    Object.keys(current_timeline.event_step_indexes)
      .filter((id) => current_timeline.event_step_indexes[id] >= new_step_index)
      .forEach((id) => {
        timeline_changes[`event_step_indexes.${id}`] = increment(1);
      });

    Object.keys(current_timeline.beat_step_indexes)
      .filter((id) => current_timeline.beat_step_indexes[id] > new_step_index && current_timeline.beat_step_indexes[id] !== 0)
      .forEach((id) => {
        timeline_changes[`beat_step_indexes.${id}`] = increment(1);
      });

    Object.keys(current_timeline.progression_condition_step_indexes)
      .filter((id) => current_timeline.progression_condition_step_indexes[id] > new_step_index && current_timeline.progression_condition_step_indexes[id] !== 0)
      .forEach((id) => {
        timeline_changes[`progression_condition_step_indexes.${id}`] = increment(1);
      });
  }

  if (insert_row) {
    Object.keys(current_timeline.row_indexes)
      .filter((id) => current_timeline.row_indexes[id] >= new_row_index)
      .forEach((id) => {
        timeline_changes[`row_indexes.${id}`] = increment(1);
      }, {});
  }

  timeline_changes[`event_step_indexes.${event_id}`] = new_step_index;

  const old_row_id = current_timeline.event_row_ids[event_id];
  const old_row_ref = doc(context.getters.firestore_refs.timeline_rows, old_row_id);

  const row_index_has_changed = new_row_index !== current_timeline.row_indexes[old_row_id] || insert_row;

  if (row_index_has_changed) {

    // If we're moving row index and not inserting a new row,
    // there may be an existing row document at the new index.
    const to_row_id = insert_row ? undefined : Object.keys(current_timeline.row_indexes)
      .find((id) => {
        return current_timeline.row_indexes[id] === new_row_index;
      });

    if (to_row_id) {
      // A row document exists at the new row index, let's update the event's row.

      timeline_changes[`event_row_ids.${event_id}`] = to_row_id;

    } else {
      // There is no existing row document at our new row index
      // so we must create one and set up any references.

      const new_row_ref = doc(context.getters.firestore_refs.timeline_rows);

      const new_row_id = new_row_ref.id;

      timeline_changes[`row_indexes.${new_row_id}`] = new_row_index;

      timeline_changes[`event_row_ids.${event_id}`] = new_row_id;

      const document = {
        title: '',
        id: new_row_id,
        timeline_id: current_timeline.id,
      };

      create_firestore_document({
        document_ref: new_row_ref,
        document,
        user_id: context.state.user_id,
        batch,
      });
    }
  }

  timeline_changes[`event_ids_being_dragged.${event_id}`] = deleteField();

  // We'll fire off the timeline write first (as we want that to complete ASAP).
  // We'll return this promise at the end of the operation as it's the only write that truly matters.

  update_firestore_document({
    document_ref: timeline_ref,
    document: timeline_changes,
    user_id: context.state.user_id,
    batch,
  });

  // Commit the batch here!
  const batch_write_promise = batch.commit();

  // If the timeline event has moved to a new row, the last row may no longer have any events.
  // If that's the case (and the user has not changed the name of the row) we'll delete it.
  // We want this to happen after the changes to the timeline have been written. However
  // we don't want the timeline to wait for the empty row deletion (which could be slow as it
  // happens inside a transaction) so we'll chain these promises but return the original
  // timeline write promise at the end.
  if (row_index_has_changed) {
    batch_write_promise
      .then(() => {
        context.dispatch('delete_current_timeline_row_if_empty', {
          timeline_row_firestore_ref: old_row_ref,
        });
      });
  }

  return batch_write_promise;
};


/**
 * Checks whether a timeline row contains no events and has not been otherwise changed
 * since creation. If both are true, the timeline row shall be deleted. If the row does not exist,
 * we must tidy up any references to that row.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {import('firebase/firestore').DocumentReference<TimelineRow>} param2.timeline_row_firestore_ref
 * @returns {Promise}
 */
actions.delete_current_timeline_row_if_empty = (context, {
  timeline_row_firestore_ref,
}) => {

  const timeline_firestore_ref = timeline_row_firestore_ref.parent.parent;


  const row_id = timeline_row_firestore_ref.id;

  if (!timeline_firestore_ref) {
    return Promise.reject();
  }

  // This must be run inside a transaction to ensure data integrity.
  return runTransaction(db, async (transaction) => {

    // First we perform & gather all our read operations. Only return common timeline fields and not publish specific ones by using the timeline resource.
    const timeline_data_promise = transaction.get(timeline_firestore_ref).then((doc) => {
      return doc.exists() ? doc.data() : undefined;
    });

    const row_data_promise = transaction.get(timeline_row_firestore_ref).then((doc) => {
      return doc.exists() ? doc.data() : undefined;
    });

    const [
      untyped_timeline_data,
      untyped_row_data,
    ] = await Promise.all([
      timeline_data_promise,
      row_data_promise,
    ]);

    const timeline_has_been_deleted = untyped_timeline_data === undefined;
    const row_has_been_deleted = untyped_row_data === undefined;

    // If the timeline has been deleted, we bail out.
    if (timeline_has_been_deleted) {
      return;
    }

    const timeline_data = /** @type {SequencedTimeline} */(untyped_timeline_data);
    const row_data = /** @type {TimelineRow} */(untyped_row_data);

    const row_still_has_events = Object.values(timeline_data.event_row_ids).filter((id) => id === row_id).length > 0;
    const row_title = row_data?.title || '';

    if (row_has_been_deleted || !row_still_has_events && (row_title === '' || row_title === 'Untitled')) {

      const timeline_changes = {
        [`row_indexes.${row_id}`]: deleteField(),
      };

      const write_promises = [];

      if (!row_has_been_deleted) {
        // We must check that the row still exists before attempting to delete it.
        write_promises.push(transaction.delete(timeline_row_firestore_ref));
      }

      write_promises.push(update_firestore_document({
        document_ref: timeline_firestore_ref,
        document: timeline_changes,
        transaction,
        user_id: context.state.user_id,
      }).promise);

      return Promise.all(write_promises);
    }
  });
};

/**
 * Moves a timeline row to a new position, shifting all rows in-between original and new positions.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {number} param2.row_index
 * @param {boolean} [param2.remove]
 * @returns {Promise}
 */
actions.shift_current_timeline_rows = (context, {
  row_index,
  remove = false,
}) => {

  if (!context.state.current_timeline) {
    return Promise.reject();
  }

  const delta = remove ? -1 : 1;

  // This contains {[row_id]: row_index}.
  const original_row_indexes = context.state.current_timeline.row_indexes;

  const timeline_changes = Object.keys(original_row_indexes).reduce((acc, row_id) => {
    if (original_row_indexes[row_id] > row_index) {
      acc[`row_indexes.${row_id}`] = original_row_indexes[row_id] + delta;
    }
    return acc;
  }, {});

  return update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: timeline_changes,
    user_id: context.state.user_id,
  }).promise;

};

/**
 * Moves a timeline row to a new position, shifting all rows in-between original and new positions.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {string} param2.row_id
 * @param {number} param2.new_row_index
 * @returns {Promise}
 */
actions.move_current_timeline_row = (context, {
  row_id,
  new_row_index,
}) => {

  if (!context.state.current_timeline) {
    return Promise.reject();
  }

  // This contains {[row_id]: row_index}.
  const original_row_indexes = context.state.current_timeline.row_indexes;

  // This inverts original_row_indexes to be {[row_index]: row_id}.
  const original_row_ids_by_row_index = Object.keys(original_row_indexes)
    .reduce((acc, id) => {
      acc[original_row_indexes[id]] = id;
      return acc;
    }, {});

  // We'll persist this at the end.
  const new_row_indexes = {
    ...context.state.current_timeline.row_indexes,
  };

  // Finds the original row index.
  const original_row_index = original_row_indexes[row_id];

  // Determine whether we're moving the row up or down.
  const move_up = new_row_index > original_row_index;

  // Finds the range of row indexes in between original and new position.
  const from = move_up ? original_row_index : new_row_index;
  const to = move_up ? new_row_index + 1 : original_row_index;

  // We'll use this value to shift each row index.
  const move_by = move_up ? -1 : 1;

  // Now to shift all the rows in-between original and new position.
  for (let i = from; i < to; i++) {
    if (original_row_ids_by_row_index[i] !== undefined) {
      new_row_indexes[original_row_ids_by_row_index[i]] = i + move_by;
    }
  }

  // Finally, set the new row index of the row we're moving.
  new_row_indexes[row_id] = new_row_index;

  // Persist it.
  return update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: {
      row_indexes: new_row_indexes,
    },
    user_id: context.state.user_id,
  }).promise;

};

/**
 * Inserts a step index.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {number} param2.step_index
 * @returns {Promise}
 */
actions.insert_current_timeline_step = (context, {
  step_index,
}) => {

  return context.dispatch('move_all_following_timeline_steps', {
    step_index,
    amount: 1,
  });

};


/**
 * Removes a step index.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {number} param2.step_index
 * @returns {Promise}
 */
actions.remove_current_timeline_step = (context, {
  step_index,
}) => {

  return context.dispatch('move_all_following_timeline_steps', {
    step_index,
    amount: -1,
  });

};


/**
 * Moves all timeline entities after `step_index` by `amount`.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {number} param2.step_index
 * @param {number} param2.amount
 * @returns {Promise}
 */
actions.move_all_following_timeline_steps = (context, {
  step_index,
  amount,
}) => {

  if (amount === 0) {
    return Promise.reject();
  }

  const current_timeline = context.state.current_timeline;

  if (!current_timeline) {
    return Promise.reject();
  }

  const timeline_changes = {};

  // If we're removing, we include the beat & progression condition at `step_index`. Otherwise, we don't.
  const compare = amount < 0 ? (a, b) => (a > b) : (a, b) => (a >= b);

  Object.keys(current_timeline.event_step_indexes)
    .filter((id) => current_timeline.event_step_indexes[id] >= step_index)
    .forEach((id) => {
      timeline_changes[`event_step_indexes.${id}`] = increment(amount);
    });

  Object.keys(current_timeline.beat_step_indexes)
    .filter((id) => compare(current_timeline.beat_step_indexes[id], step_index) && current_timeline.beat_step_indexes[id] !== 0)
    .forEach((id) => {
      timeline_changes[`beat_step_indexes.${id}`] = increment(amount);
    });

  Object.keys(current_timeline.progression_condition_step_indexes)
    .filter((id) => compare(current_timeline.progression_condition_step_indexes[id], step_index) && current_timeline.progression_condition_step_indexes[id] !== 0)
    .forEach((id) => {
      timeline_changes[`progression_condition_step_indexes.${id}`] = increment(amount);
    });

  return update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: timeline_changes,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Moves a beat to a new step_index.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {string} param2.beat_id
 * @param {number} param2.new_step_index
 * @returns {Promise}
 */
actions.move_current_timeline_beat = (context, {
  beat_id,
  new_step_index,
}) => {

  return update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: {
      [`beat_step_indexes.${beat_id}`]: new_step_index,
      [`beat_ids_being_dragged.${beat_id}`]: deleteField(),
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Moves a progression_condition to a new step_index.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {string} param2.progression_condition_id
 * @param {number} param2.new_step_index
 * @returns {Promise}
 */
actions.move_current_timeline_progression_condition = (context, {
  progression_condition_id,
  new_step_index,
}) => {

  return update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: {
      [`progression_condition_step_indexes.${progression_condition_id}`]: new_step_index,
      [`progression_condition_ids_being_dragged.${progression_condition_id}`]: deleteField(),
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Called when a user starts or stops dragging an event on the current timeline.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.event_id
 * @param {boolean} param1.is_dragging
 * @returns {Promise}
 */
actions.handle_dragging_current_timeline_event = (context, {
  event_id,
  is_dragging,
}) => {

  let new_val;

  if (is_dragging) {
    new_val = context.state.user_id;
  } else {
    new_val = deleteField();
  }

  return update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: {
      [`event_ids_being_dragged.${event_id}`]: new_val,
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Called when a user starts or stops dragging an progression condition on the current timeline.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.progression_condition_id
 * @param {boolean} param1.is_dragging
 * @returns {Promise}
 */
actions.handle_dragging_current_timeline_progression_condition = (context, {
  progression_condition_id,
  is_dragging,
}) => {

  const progression_condition_ids_being_dragged_val = is_dragging ? context.state.user_id : deleteField();

  return update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: {
      [`progression_condition_ids_being_dragged.${progression_condition_id}`]: progression_condition_ids_being_dragged_val,
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Called when a user starts or stops dragging a beat on the current timeline.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.beat_id
 * @param {boolean} param1.is_dragging
 * @returns {Promise}
 */
actions.handle_dragging_current_timeline_beat = (context, {
  beat_id,
  is_dragging,
}) => {

  const beat_ids_being_dragged_val = is_dragging ? context.state.user_id : deleteField();

  return update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: {
      [`beat_ids_being_dragged.${beat_id}`]: beat_ids_being_dragged_val,
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Deletes a reference for two events
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {String[]} param1.timeline_events two event ids to disconnect
 * @returns {Promise}
 */
actions.delete_timeline_event_reference_for_current_timeline = (context, {
  timeline_events,
}) => {
  const composite_timeline_event_reference_id = timeline_events.sort().join(':');

  const timeline_event_references_ref = context.getters.firestore_refs.current.timeline_event_references;

  return update_firestore_document({
    document_ref: timeline_event_references_ref,
    document: {
      [`explicit_timeline_event_references.${composite_timeline_event_reference_id}`]: deleteField(),
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Creates a reference for two events with a direction
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {String} param1.from the source event in the reference
 * @param {String} param1.to the target event in the reference
 * @param {Boolean} param1.two_way true if the relationship should be bidirectional
 * @returns {Promise}
 */
actions.add_directional_timeline_event_reference = (context, {
  from, to, two_way,
}) => {
  const composite_timeline_event_reference_id = create_composite_id({
    ids: [
      from,
      to,
    ],
    sort_alpha: true,
  });
  const timeline_event_references_ref = context.getters.firestore_refs.current.timeline_event_references;
  let direction;

  if (two_way) {
    direction = timeline_event_reference_directions_map.BIDIRECTIONAL;
  } else if (composite_timeline_event_reference_id.startsWith(from)) {
    direction = timeline_event_reference_directions_map.UNIDIRECTIONAL;
  } else {
    direction = timeline_event_reference_directions_map.UNIDIRECTIONAL_INVERSE;
  }

  return update_firestore_document({
    document_ref: timeline_event_references_ref,
    document: {
      [`explicit_timeline_event_references.${composite_timeline_event_reference_id}`]: {
        direction,
      },
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Creates a row at the provided `step_index` (bails if one exists already).
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {number} param1.row_index
 * @returns {Promise}
 */
actions.create_row_for_current_timeline = async (context, {
  row_index,
}) => {

  if (!context.state.current_timeline || !context.getters.firestore_refs.timeline_rows) {
    return;
  }

  if (Object.values(context.state.current_timeline.row_indexes).includes(row_index)) {
    // A row already exists at this index.
    return;
  }

  const timeline_id = context.state.current_timeline_id;

  const batch = writeBatch(db);

  const timeline_ref = context.getters.firestore_refs.current.timeline;

  const timeline_row_ref = doc(context.getters.firestore_refs.timeline_rows);

  /** @type {TimelineRow} */
  const timeline_row = {
    id: timeline_row_ref.id,
    title: '',
    timeline_id,
  };

  create_firestore_document({
    batch,
    document_ref: timeline_row_ref,
    document: timeline_row,
    user_id: context.state.user_id,
  });

  const timeline_changes = {
    [`row_indexes.${timeline_row_ref.id}`]: row_index,
  };

  update_firestore_document({
    batch,
    document_ref: timeline_ref,
    document: timeline_changes,
    user_id: context.state.user_id,
  });

  return batch.commit();
};

/**
 * Sets a new title and description on row with id of `id`.
 *
 * @param {ActionContext} context
 * @param {Partial<TimelineRow>} param1
 * @returns {Promise}
 */
actions.update_current_timeline_row = async (context, {
  id,
  title,
  description,
}) => {

  if (!context.state.current_timeline || !context.getters.firestore_refs.timeline_rows || !id) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.timeline_rows, id);

  /** @type {Partial<TimelineRow>} */
  const document = {
    id,
    title,
    timeline_id: context.state.current_timeline.id,
  };

  if (description) {
    document.description = description;
  }

  return update_firestore_document({
    document,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Empties and deletes a timeline row (inside a transaction) including all timeline_events.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.row_id
 * @returns {Promise}
 */
actions.delete_current_timeline_row = async (context, {
  row_id,
}) => {

  if (!context.getters.firestore_refs.timeline_rows) {
    return Promise.reject();
  }

  const workspace_id = context.state.current_workspace_id;
  const project_id = context.state.current_project_id;
  const timeline_id = context.state.current_timeline_id;
  const is_published = context.state.current_timeline_is_published;

  // We're going to delete all timeline_events and timeline_threads related to this row.
  const timeline_ref = context.getters.firestore_refs.current.timeline;
  const timeline_event_references_ref = context.getters.firestore_refs.current.timeline_event_references;
  const row_ref = timeline_row_refs.document({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
    timeline_row_id: row_id,
  });
  const events_collection_ref = context.getters.firestore_refs.timeline_events;
  const threads_collection_ref = context.getters.firestore_refs.timeline_linked_threads;
  const event_links_ref = context.getters.firestore_refs.timeline_event_links;

  if (!timeline_ref || !timeline_event_references_ref || !events_collection_ref || !event_links_ref || !threads_collection_ref) {
    return Promise.reject();
  }

  return runTransaction(db, async (transaction) => {
    const write_promises = [];

    const [
      timeline_doc,
      timeline_event_references_doc,
      row_doc,
    ] = await Promise.all([
      transaction.get(timeline_ref),
      transaction.get(timeline_event_references_ref),
      transaction.get(row_ref),
    ]);

    if (!timeline_doc.exists() || !row_doc.exists()) {
      // Either the row or the timeline has been deleted, so we bail.
      return;
    }

    const timeline_data = timeline_doc.data();
    const timeline_event_references_data = timeline_event_references_doc.data() || {
      event_link_ids: {},
      explicit_timeline_event_references: {},
      events_in_threads: {},
    };
    const event_link_ids = timeline_event_references_data.event_link_ids || {};

    // Transaction-safe version of context.getters.current_timeline_events_with_multiple_links.
    const current_timeline_events_with_multiple_links = timeline_events_with_multiple_links({
      event_link_ids: Object.keys(event_link_ids),
    });

    // We're going to store a load of changes here and apply them at the end.
    const timeline_changes = {
      [`row_indexes.${row_id}`]: deleteField(),
    };
    const timeline_event_references_changes = {};
    const event_link_id_changes = {
      ...event_link_ids,
    };

    // Shift row indexes so that the removed row can disappear.
    const deleted_index = timeline_data.row_indexes[row_id];
    for (const [
      key,
      value,
    ] of Object.entries(timeline_data.row_indexes)) {
      if (value > deleted_index) {
        timeline_changes[`row_indexes.${key}`] = increment(-1);
      }
    }

    // Identify a list of events that require deletion
    const event_ids_to_delete = Object.keys(timeline_data.event_row_ids).filter(
      (event_id) => timeline_data.event_row_ids[event_id] === row_id
    );

    // Delete relevant entries for explicit_timeline_event_references
    Object.keys(timeline_event_references_data.explicit_timeline_event_references)
      .forEach((compound_key) => {

        const should_delete = split_composite_id({
          id: compound_key,
        }).some( (event_id) => event_ids_to_delete.includes(event_id));

        if (should_delete) {
          timeline_event_references_changes[`explicit_timeline_event_references.${compound_key}`] = deleteField();
        }
      }, {});

    // Delete each event in the row, including links and all other references on the timeline doc.
    event_ids_to_delete.forEach((event_id) => {
      // Delete the timeline event document
      const ref = timeline_event_refs.document({
        workspace_id,
        project_id,
        timeline_id,
        is_published,
        timeline_event_id: event_id,
      });
      write_promises.push(transaction.delete(ref));

      // Delete the event's step index.
      timeline_changes[`event_step_indexes.${event_id}`] = deleteField();

      // Delete the event's row ID.
      timeline_changes[`event_row_ids.${event_id}`] = deleteField();

      // Remove any references to the event being dragged.
      timeline_changes[`event_ids_being_dragged.${event_id}`] = deleteField();

      // Delete all 'to' links that exist for the event
      const next_linked_event_ids = current_timeline_events_with_multiple_links.to_event_ids[event_id];

      if (next_linked_event_ids?.length) {
        next_linked_event_ids.forEach((linked_event_id) => {
          const link_id = create_composite_id({
            ids: [
              event_id,
              linked_event_id,
            ],
          });
          const link_ref = timeline_event_link_refs.document({
            workspace_id,
            project_id,
            timeline_id,
            is_published,
            timeline_event_link_id: link_id,
          });
          write_promises.push(transaction.delete(link_ref));
          timeline_event_references_changes[`event_link_ids.${link_id}`] = deleteField();
          delete event_link_id_changes[link_id];
        });
      }

      // Delete all 'from' links that exist for the event
      const prev_linked_event_ids = current_timeline_events_with_multiple_links.from_event_ids[event_id];

      if (prev_linked_event_ids?.length) {
        prev_linked_event_ids.forEach((linked_event_id) => {
          const link_id = create_composite_id({
            ids: [
              linked_event_id,
              event_id,
            ],
          });
          const link_ref = doc(event_links_ref, link_id);
          write_promises.push(transaction.delete(link_ref));
          timeline_event_references_changes[`event_link_ids.${link_id}`] = deleteField();
          delete event_link_id_changes[link_id];
        });
      }
    });

    write_promises.push(update_firestore_document({
      transaction,
      document: timeline_changes,
      document_ref: timeline_ref,
      user_id: context.state.user_id,
    }).promise);

    write_promises.push(update_firestore_document({
      document: timeline_event_references_changes,
      document_ref: timeline_event_references_ref,
      user_id: context.state.user_id,
      transaction,
    }).promise);

    write_promises.push(regenerate_threads({
      event_link_ids: event_link_id_changes,
      old_events_in_threads: timeline_event_references_data.events_in_threads,
      threads_collection_ref,
      timeline_event_references_ref,
      transaction,
      user_id: context.state.user_id,
    }));

    // Delete the row
    write_promises.push(transaction.delete(row_ref));

    return Promise.all(write_promises);
  });
};

/**
 * Creates a new timeline event at step_index and row_index. May create a new row
 * and thread, if needed. Does not create a narrative event.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {number} param1.step_index
 * @param {number} param1.row_index
 * @param {string} [param1.narrative_event_id]
 * @returns {Promise<string>}
 */
actions.create_current_timeline_event = (context, {
  step_index,
  row_index,
  narrative_event_id,
}) => {

  if (!context.state.current_timeline || !context.getters.firestore_refs.timeline_events || !context.getters.firestore_refs.narrative_events || !context.getters.firestore_refs.timeline_rows) {
    return Promise.reject('');
  }

  // We're creating a new timeline event and updating
  // timeline event properties on the timeline in a single batch operation.
  // We may also need to create a new timeline row for the event.

  const timeline_ref = context.getters.firestore_refs.current.timeline;

  const new_timeline_event_ref = doc(context.getters.firestore_refs.timeline_events);

  const event_id = new_timeline_event_ref.id;

  const batch = writeBatch(db);

  const document = {
    status: timeline_event_status_map.ENABLED,
    timeline_id: context.state.current_timeline_id,
    is_locked: false,
  };

  if (narrative_event_id) {
    document.narrative_event_id = narrative_event_id;
  } else {
    const new_narrative_event_ref = doc(context.getters.firestore_refs.narrative_events);

    create_firestore_document({
      document: {
        title: '',
        description: '',
      },
      document_ref: new_narrative_event_ref,
      user_id: context.state.user_id,
      batch,
    });

    document.narrative_event_id = new_narrative_event_ref.id;
  }

  const new_timeline_event = create_firestore_document({
    batch,
    document_ref: new_timeline_event_ref,
    document,
    user_id: context.state.user_id,
  }).document;

  const timeline_changes = {};

  timeline_changes[`event_step_indexes.${event_id}`] = step_index;

  // We may already have a row at this step index.
  let row_id = Object.keys(context.state.current_timeline.row_indexes)
    .find((id) => {
      return context.state.current_timeline?.row_indexes[id] === row_index;
    });

  if (!row_id) {

    // A row does not exist at this row_index so we must create one.
    let new_row_ref = doc(context.getters.firestore_refs.timeline_rows);

    row_id = new_row_ref.id;

    create_firestore_document({
      batch,
      document_ref: new_row_ref,
      document: {
        title: '',
        timeline_id: context.state.current_timeline_id,
      },
      user_id: context.state.user_id,
    });

    timeline_changes[`row_indexes.${row_id}`] = row_index;
  }

  timeline_changes[`event_row_ids.${event_id}`] = row_id;

  update_firestore_document({
    batch,
    document_ref: timeline_ref,
    document: timeline_changes,
    user_id: context.state.user_id,
  });

  context.commit(SET_CURRENT_TIMELINE_EVENT_BEING_CREATED, {
    id: event_id,
    ...new_timeline_event,
    step_index,
    row_index,
    row_id,
  });

  return batch.commit()
    .then(() => {
      context.commit(CLEAR_CURRENT_TIMELINE_EVENT_BEING_CREATED, {
        id: event_id,
      });
    }).then(() => event_id);
};

/**
 * Updates a timeline event on the current timeline.
 *
 * @param {ActionContext} context
 * @param {Partial<TimelineEvent>} timeline_event
 * @returns {Promise}
 */
actions.update_current_timeline_event = async (context, timeline_event) => {

  if (!context.getters.firestore_refs.timeline_events) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.timeline_events, timeline_event.id);

  return update_firestore_document({
    document: timeline_event,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Deletes a timeline event from the current timeline.
 *
 * @param {ActionContext} context
 * @param {string} timeline_event_id
 * @returns {Promise}
 */
actions.delete_current_timeline_event = async (context, timeline_event_id) => {

  if (!context.getters.firestore_refs.timeline_events) {
    return Promise.reject();
  }

  const timeline_ref = context.getters.firestore_refs.current.timeline;
  const timeline_event_references_ref = context.getters.firestore_refs.current.timeline_event_references;
  const threads_collection_ref = context.getters.firestore_refs.timeline_linked_threads;
  const event_ref = doc(context.getters.firestore_refs.timeline_events, timeline_event_id);
  const links_ref = context.getters.firestore_refs.timeline_event_links;
  const rows_ref = context.getters.firestore_refs.timeline_rows;

  if (!timeline_ref || !timeline_event_references_ref || !links_ref || !rows_ref || !threads_collection_ref) {
    return Promise.reject();
  }

  return runTransaction(db, async (transaction) => {

    const [
      timeline_firestore_doc,
      timeline_event_references_firestore_doc,
      event_doc,
    ] = await Promise.all([
      transaction.get(timeline_ref),
      transaction.get(timeline_event_references_ref),
      transaction.get(event_ref),
    ]);

    // If either the timeline or the event have been deleted since the transaction was called
    // we do nothing.
    if (!timeline_firestore_doc.exists() || !event_doc.exists()) {
      return;
    }

    // We'll store any write promises here.
    const write_promises = [];

    // We're going to store a load of changes here and apply them at the end.
    let timeline_changes = {};
    let timeline_event_references_changes = {};

    // Delete the event's step index.
    timeline_changes[`event_step_indexes.${timeline_event_id}`] = deleteField();

    // Delete the event's row ID.
    timeline_changes[`event_row_ids.${timeline_event_id}`] = deleteField();

    // Remove any references to the event being dragged.
    timeline_changes[`event_ids_being_dragged.${timeline_event_id}`] = deleteField();

    // Delete relevant entries on related explicit_timeline_event_references.
    Object.keys(timeline_event_references_firestore_doc.data()?.explicit_timeline_event_references || {})
      .forEach((compound_key) => {

        const should_delete = split_composite_id({
          id: compound_key,
        }).includes(timeline_event_id);

        if (should_delete) {
          timeline_event_references_changes[`explicit_timeline_event_references.${compound_key}`] = deleteField();
        }
      }, {});

    write_promises.push(transaction.delete(event_ref));

    const timeline_event_references_data = /** @type {TimelineEventReference} */(timeline_event_references_firestore_doc.data());
    const event_link_ids = timeline_event_references_data?.event_link_ids || {};
    const new_event_link_ids = {
      ...event_link_ids,
    };

    // Transaction-safe version of context.getters.current_timeline_events_with_multiple_links.
    const current_timeline_events_with_multiple_links = timeline_events_with_multiple_links({
      event_link_ids: Object.keys(event_link_ids),
    });

    // Delete all 'to' links that exist for the event
    const next_linked_event_ids = current_timeline_events_with_multiple_links.to_event_ids[timeline_event_id];

    if (next_linked_event_ids?.length) {
      next_linked_event_ids.forEach((linked_event_id) => {
        const link_id = create_composite_id({
          ids: [
            timeline_event_id,
            linked_event_id,
          ],
        });
        const link_ref = doc(links_ref, link_id);
        write_promises.push(transaction.delete(link_ref));
        timeline_event_references_changes[`event_link_ids.${link_id}`] = deleteField();
        delete new_event_link_ids[link_id];
      });
    }

    // Delete all 'from' links that exist for the event
    const prev_linked_event_ids = current_timeline_events_with_multiple_links.from_event_ids[timeline_event_id];

    if (prev_linked_event_ids?.length) {
      prev_linked_event_ids.forEach((linked_event_id) => {
        const link_id = create_composite_id({
          ids: [
            linked_event_id,
            timeline_event_id,
          ],
        });
        const link_ref = doc(links_ref, link_id);
        write_promises.push(transaction.delete(link_ref));
        timeline_event_references_changes[`event_link_ids.${link_id}`] = deleteField();
        delete new_event_link_ids[link_id];
      });

    }

    write_promises.push(update_firestore_document({
      document: timeline_changes,
      document_ref: timeline_ref,
      user_id: context.state.user_id,
      transaction,
    }).promise);

    write_promises.push(update_firestore_document({
      document: timeline_event_references_changes,
      document_ref: timeline_event_references_ref,
      user_id: context.state.user_id,
      transaction,
    }).promise);

    const timeline_doc = /** @type {NonNullable<ReturnType<typeof timeline_firestore_doc.data>>} */(timeline_firestore_doc.data());

    if (next_linked_event_ids?.length || prev_linked_event_ids?.length) {
      // We can use this to determine the new_timeline_event_ids.
      const event_step_indexes = timeline_doc.event_step_indexes;

      const new_event_step_indexes = {
        ...event_step_indexes,
      };
      delete new_event_step_indexes[timeline_event_id];

      const thread_changes_promise = regenerate_threads({
        event_link_ids: new_event_link_ids,
        old_events_in_threads: timeline_event_references_data.events_in_threads,
        threads_collection_ref,
        timeline_event_references_ref,
        transaction,
        user_id: context.state.user_id,
      });

      write_promises.push(thread_changes_promise);
    }

    const write_promise = Promise.all(write_promises);

    const row_id = timeline_doc.event_row_ids[timeline_event_id];
    const row_ref = doc(rows_ref, row_id);
    write_promise
      .then(() => {
        context.dispatch('delete_current_timeline_row_if_empty', {
          timeline_row_firestore_ref: row_ref,
        });
      });

    return write_promise;
  });
};


/**
 * Deletes existing custom data.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.narrative_event_id
 * @param {NarrativeEventCustomData} param1.datum
 * @returns {Promise}
 */
actions.delete_custom_data = (context, {
  narrative_event_id,
  datum,
}) => {
  if (!context.getters.firestore_refs.narrative_events) {
    return Promise.reject();
  }
  const document_ref = doc(context.getters.firestore_refs.narrative_events, narrative_event_id);
  return update_firestore_document({
    document_ref,
    document: {
      custom_data: arrayRemove(datum),
    },
    user_id: context.state.user_id,
  }).promise;
};


/**
 * Replacers existing custom data.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.narrative_event_id
 * @param {NarrativeEventCustomData} param1.datum
 * @param {NarrativeEventCustomData} [param1.old_datum]
 * @returns {Promise}
 */
actions.update_custom_data = (context, {
  narrative_event_id,
  datum,
  old_datum,
}) => {

  if (!context.getters.firestore_refs.narrative_events) {
    return Promise.reject();
  }

  const batch = writeBatch(db);

  if (old_datum) {
    update_firestore_document({
      document_ref: doc(context.getters.firestore_refs.narrative_events, narrative_event_id),
      document: {
        custom_data: arrayRemove(old_datum),
      },
      user_id: context.state.user_id,
      batch,
    });
  }

  update_firestore_document({
    document_ref: doc(context.getters.firestore_refs.narrative_events, narrative_event_id),
    document: {
      custom_data: arrayUnion(datum),
    },
    user_id: context.state.user_id,
    batch,
  });

  return batch.commit();

};


/**
 * Creates a new timeline beat at step_index.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {number} param1.step_index
 * @returns {Promise<string>}
 */
actions.create_current_timeline_beat = (context, {
  step_index,
}) => {

  if (!context.getters.firestore_refs.timeline_beats) {
    return Promise.reject();
  }

  // We're creating a new timeline beat and updating
  // timeline beat properties on the timeline in a single batch operation.

  const timeline_ref = context.getters.firestore_refs.current.timeline;

  const new_timeline_beat_ref = doc(context.getters.firestore_refs.timeline_beats);

  const beat_id = new_timeline_beat_ref.id;

  const batch = writeBatch(db);

  create_firestore_document({
    batch,
    document_ref: new_timeline_beat_ref,
    document: {
      timeline_id: context.state.current_timeline_id,
      title: '',
    },
    user_id: context.state.user_id,
  });

  const timeline_changes = {};

  timeline_changes[`beat_step_indexes.${beat_id}`] = step_index;

  update_firestore_document({
    batch,
    document_ref: timeline_ref,
    document: timeline_changes,
    user_id: context.state.user_id,
  });

  return batch.commit()
    .then(() => beat_id);
};

/**
 * Updates a timeline beat on the current timeline.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.id
 * @param {string} param1.title
 * @param {string} [param1.description]
 * @returns {Promise}
 */
actions.update_current_timeline_beat = async (context, {
  id,
  title,
  description,
}) => {

  if (!context.getters.firestore_refs.timeline_beats) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.timeline_beats, id);

  const document = {
    title,
  };

  if (description) {
    document.description = description;
  }

  return update_firestore_document({
    document,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Deletes a timeline beat from the current timeline.
 *
 * @param {ActionContext} context
 * @param {Object} param0
 * @param {string} param0.beat_id
 * @returns {Promise}
 */
actions.delete_current_timeline_beat = async (context, {
  beat_id,
}) => {

  if (!context.getters.firestore_refs.current.timeline || !context.getters.firestore_refs.timeline_beats) {
    return Promise.reject();
  }

  const timeline_ref = context.getters.firestore_refs.current.timeline;

  const beat_ref = doc(context.getters.firestore_refs.timeline_beats, beat_id);

  return runTransaction(db, async (transaction) => {

    const timeline_firestore_doc = await transaction.get(timeline_ref);

    if (!timeline_firestore_doc.exists()) {
      return;
    }

    const timeline = /** @type {NonNullable<ReturnType<typeof timeline_firestore_doc.data>>} */(timeline_firestore_doc.data());

    const timeline_changes = {};

    // Gather the beat_step_indexes entries we must delete.
    Object.keys(timeline.beat_step_indexes)
      .filter((id) => id === beat_id)
      .reduce((timeline_changes, id) => {
        timeline_changes[`beat_step_indexes.${id}`] = deleteField();
        return timeline_changes;
      }, timeline_changes);

    // Remove any references to the beat being dragged.
    Object.keys(timeline.beat_ids_being_dragged)
      .filter((id) => id === beat_id)
      .reduce((timeline_changes, id) => {
        timeline_changes[`beat_ids_being_dragged.${id}`] = deleteField();
        return timeline_changes;
      }, timeline_changes);

    // We'll store all transaction write promises in this array so we can return
    // a promise value to keep the transaction resolution snappy.
    const transaction_write_promises = [];

    if (Object.keys(timeline_changes).length) {

      const timeline_write_promise = update_firestore_document({
        document: timeline_changes,
        document_ref: timeline_ref,
        user_id: context.state.user_id,
        transaction,
      }).promise;

      transaction_write_promises.push(timeline_write_promise);
    }

    transaction_write_promises.push(transaction.delete(beat_ref));

    return Promise.all(transaction_write_promises);
  });
};

/**
 * Creates a new timeline progression condition at step_index.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {number} param1.step_index
 * @returns {Promise<string>}
 */
actions.create_current_timeline_progression_condition = (context, {
  step_index,
}) => {

  if (!context.getters.firestore_refs.timeline_progression_conditions) {
    return Promise.reject();
  }

  // We're creating a new timeline progression condition and updating
  // timeline progression condition properties on the timeline in a single batch operation.

  const timeline_ref = context.getters.firestore_refs.current.timeline;

  const new_timeline_progression_condition_ref = doc(context.getters.firestore_refs.timeline_progression_conditions);

  const progression_condition_id = new_timeline_progression_condition_ref.id;

  const batch = writeBatch(db);

  create_firestore_document({
    batch,
    document_ref: new_timeline_progression_condition_ref,
    document: {
      timeline_id: context.state.current_timeline_id,
      title: '',
    },
    user_id: context.state.user_id,
  }).document;

  const timeline_changes = {};

  timeline_changes[`progression_condition_step_indexes.${progression_condition_id}`] = step_index;

  update_firestore_document({
    batch,
    document_ref: timeline_ref,
    document: timeline_changes,
    user_id: context.state.user_id,
  });

  return batch.commit()
    .then(() => progression_condition_id);
};

/**
 * Updates a timeline progression condition on the current timeline.
 *
 * @param {ActionContext} context
 * @param {Partial<TimelineProgressionCondition>} changes
 * @returns {Promise}
 */
actions.update_current_timeline_progression_condition = async (context, changes) => {

  if (!context.getters.firestore_refs.timeline_progression_conditions) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.timeline_progression_conditions, changes.id);

  return update_firestore_document({
    document: changes,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Removes a timeline progression condition from the current timeline.
 *
 * @param {ActionContext} context
 * @param {Object} param0
 * @param {string} param0.progression_condition_id
 * @returns {Promise}
 */
actions.delete_current_timeline_progression_condition = async (context, {
  progression_condition_id,
}) => {

  if (!context.getters.firestore_refs.current.timeline || !context.getters.firestore_refs.timeline_progression_conditions) {
    return Promise.reject();
  }

  const timeline_ref = context.getters.firestore_refs.current.timeline;

  const progression_condition_ref = doc(context.getters.firestore_refs.timeline_progression_conditions, progression_condition_id);

  return runTransaction(db, async (transaction) => {

    const timeline_firestore_doc = await transaction.get(timeline_ref);

    if (!timeline_firestore_doc.exists()) {
      return;
    }

    const timeline = /** @type {NonNullable<ReturnType<typeof timeline_firestore_doc.data>>} */(timeline_firestore_doc.data());

    const timeline_changes = {};

    // Gather the progression_condition_step_indexes entries we must delete.
    Object.keys(timeline.progression_condition_step_indexes)
      .filter((id) => id === progression_condition_id)
      .reduce((timeline_changes, id) => {
        timeline_changes[`progression_condition_step_indexes.${id}`] = deleteField();
        return timeline_changes;
      }, timeline_changes);

    // Remove any references to the progression_condition being dragged.
    Object.keys(timeline.progression_condition_ids_being_dragged)
      .filter((id) => id === progression_condition_id)
      .reduce((timeline_changes, id) => {
        timeline_changes[`progression_condition_ids_being_dragged.${id}`] = deleteField();
        return timeline_changes;
      }, timeline_changes);

    // We'll store all transaction write promises in this array so we can return
    // a promise value to keep the transaction resolution snappy.
    const transaction_write_promises = [];

    if (Object.keys(timeline_changes).length) {

      const timeline_write_promise = update_firestore_document({
        document: timeline_changes,
        document_ref: timeline_ref,
        user_id: context.state.user_id,
        transaction,
      }).promise;

      transaction_write_promises.push(timeline_write_promise);
    }

    transaction_write_promises.push(transaction.delete(progression_condition_ref));

    return Promise.all(transaction_write_promises);
  });
};

/**
 * Adds a new empty rule to either a timeline_event_link or timeline_progression_condition.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {string} param2.id
 * @param {string} param2.type
 * @returns {Promise}
 */
actions.add_new_condition_rule = (context, {
  id,
  type,
}) => {

  if (!context.getters.firestore_refs.timeline_event_links || !context.getters.firestore_refs.timeline_progression_conditions) {
    return Promise.reject();
  }

  let rule_id;
  let document_ref;

  if (type === 'LINK') {
    rule_id = doc(context.getters.firestore_refs.timeline_event_links).id;
    document_ref = doc(context.getters.firestore_refs.timeline_event_links, id);
  } else {
    rule_id = doc(context.getters.firestore_refs.timeline_progression_conditions).id;
    document_ref = doc(context.getters.firestore_refs.timeline_progression_conditions, id);
  }

  return update_firestore_document({
    document_ref,
    document: {
      [`rules.${rule_id}`]: rule_fields_defaults,
      rules_order: arrayUnion(rule_id),
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Updates a rule on a link or progression condition.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.id
 * @param {string} param1.type
 * @param {string} param1.rule_id
 * @param {RuleFields} param1.rule
 * @returns {Promise}
 */
actions.update_condition_rule = (context, {
  id,
  type,
  rule_id,
  rule,
}) => {

  if (!context.getters.firestore_refs.timeline_event_links || !context.getters.firestore_refs.timeline_progression_conditions) {
    return Promise.reject();
  }

  let document_ref;

  if (type === 'LINK') {
    document_ref = doc(context.getters.firestore_refs.timeline_event_links, id);
  } else {
    document_ref = doc(context.getters.firestore_refs.timeline_progression_conditions, id);
  }

  return update_firestore_document({
    document_ref,
    document: {
      [`rules.${rule_id}`]: rule,
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Deletes a rule from a link or progression condition.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.id
 * @param {string} param1.rule_id
 * @param {string} param1.type
 * @returns {Promise}
 */
actions.delete_condition_rule = (context, {
  rule_id,
  id,
  type,
}) => {

  if (!context.getters.firestore_refs.timeline_event_links || !context.getters.firestore_refs.timeline_progression_conditions) {
    return Promise.reject();
  }

  let document_ref;

  if (type === 'LINK') {
    document_ref = doc(context.getters.firestore_refs.timeline_event_links, id);
  } else {
    document_ref = doc(context.getters.firestore_refs.timeline_progression_conditions, id);
  }

  return update_firestore_document({
    document_ref,
    document: {
      [`rules.${rule_id}`]: deleteField(),
      rules_order: arrayRemove(rule_id),
    },
    user_id: context.state.user_id,
  }).promise;
};


/**
 * Creates a non-published timeline from a published timeline (assigning a new name).
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {string} param2.id
 * @param {string} param2.title
 * @returns {Promise}
 */
actions.copy_published_timeline_to_draft_by_id = async (context, {
  id,
  title,
}) => {
  return context.dispatch('duplicate_timeline', {
    timeline_id: id,
    title,
    is_unpublish: true,
  });
};

/**
 * Duplicates a non-published (assigning a new name).
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {string} param2.id
 * @param {string} param2.title
 * @returns {Promise}
 */
actions.duplicate_timeline_by_id = async (context, {
  id,
  title,
}) => {
  return context.dispatch('duplicate_timeline', {
    timeline_id: id,
    title,
  });
};

/**
 * @param {ActionContext} context
 * @param {string} redirect_url
 * @returns {Promise}
 */
actions.firebase_logout = async (context, redirect_url) => {

  try {
    await sign_out();
    await context.dispatch('unbind_user');
    if (!redirect_url) {
      location.reload();
    } else {
      location.href = redirect_url;
    }
  } catch (err) {
    logger.error(err);
    return err;
  }
};

/**
 * @param {ActionContext} context
 * @returns {Promise}
 */
actions.firebase_login = async (context) => {

  let signed_in;

  if (context.state.sign_in_promise) {
    // Sign-in is in-progress.
    return context.state.sign_in_promise;
  }

  try {
    let signed_in_promise = sign_in();
    context.commit(SET_SIGN_IN_PROMISE, signed_in_promise);
    signed_in = await signed_in_promise;
    // The call to sign-in may be an async operation or may
    // involve a page reload (signInWithRedirect). The following code
    // will only run if the sign-in operation was run async. Otherwise,
    // the code in bootstrap-data-vuex-plugin.js shall handle the page
    // reload case.
    if (!signed_in) {
      context.commit(CLEAR_SIGN_IN_PROMISE);
      context.commit(SET_SIGNED_IN, false);
    } else {
      await bootstrap_data_vuex_plugin(context);
    }
  } catch (err) {
    context.commit(CLEAR_SIGN_IN_PROMISE);
    context.commit(SET_SIGNED_IN, false);
    logger.error(err);
    return err;
  }

  context.commit(CLEAR_SIGN_IN_PROMISE);

  context.commit(SET_SIGNED_IN, signed_in !== null);

  return signed_in;
};

/**
 * @param {ActionContext} context
 * @returns {Promise}
 */
actions.fetch_bootstrap_data = async (context) => {
  // We must first bind the user as this ensures the workspaces
  // stored in the user's custom claims matches those in firestore/
  await context.dispatch('bind_user');
  await context.dispatch('bind_workspaces');
  await context.dispatch('set_current_workspace');
};

/**
 * Sets the current workspace to the passed ID (falling back to the first returned
 * workspace if none found). Also handles tracking the user's last active workspace.
 *
 * @param {ActionContext} context
 * @param {string} workspace_id
 * @returns {Promise}
 */
actions.set_current_workspace = async (context, workspace_id) => {

  if (!Object.values(context.state.workspaces).length) {
    throw new Error('No workspaces available');
  }

  if (!context.state.user) {
    throw new Error('No user');
  }

  if (!workspace_id) {
    workspace_id = context.state.user.last_active_workspace_id;
  }

  const found_workspace = context.state.workspaces[workspace_id] || Object.values(context.state.workspaces).sort((a, b) => a.name < b.name ? -1 : 1)[0];

  if (found_workspace.id !== context.state.user.last_active_workspace_id) {
    // Track this workspace_id as the user's last active workspace.
    context.dispatch('update_current_user', {
      last_active_workspace_id: found_workspace.id,
    });
  }

  context.commit(SET_CURRENT_WORKSPACE_ID, found_workspace.id);

  context.commit(SET_CURRENT_WORKSPACE_ROLE);

  await context.dispatch('unset_current_project');

  Sentry.setTag('workspace_id', found_workspace.id);

  return context.dispatch('bind_current_workspace_references');
};

/**
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.project_id
 * @returns {Promise}
 */
actions.set_current_project = async (context, {
  project_id,
}) => {

  context.commit(SET_CURRENT_PROJECT_ID, project_id);

  await context.dispatch('bind_current_project_references');

  if (!context.state.current_project) {

    const query_workspaces = await Promise.all([
      ...Object.keys(context.state.workspaces)
        .map((id) => {
          const doc_ref = project_refs.document({
            workspace_id: id,
            project_id,
          });
          return getDoc(doc_ref).then((doc) => doc.exists() ? id : null);
        }),
    ]);

    const valid_workspace_id = query_workspaces.find((id) => id !== null);

    if (valid_workspace_id) {
      await context.dispatch('set_current_workspace', valid_workspace_id);
      return context.dispatch('set_current_project', {
        project_id,
      });
    }

    context.commit(SET_404_ROUTE_ERROR);
    await context.dispatch('unset_current_project');
  }
};

/**
 * @param {ActionContext} context
 */
actions.unset_current_project = (context) => {

  context.commit(CLEAR_CURRENT_PROJECT_ID);

  context.dispatch('unbind_current_project_references');
};

/**
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.timeline_id
 * @param {boolean} [param1.is_published]
 * @returns {Promise}
 */
actions.set_current_timeline = async (context, {
  timeline_id,
  is_published = false,
}) => {

  context.commit(CLEAR_CURRENT_TIMELINE_IS_READY);

  context.commit(SET_CURRENT_TIMELINE_IS_PUBLISHED, is_published);

  context.commit(SET_CURRENT_TIMELINE_ID, timeline_id);

  context.commit(CLEAR_CURRENT_TIMELINE_EVENTS_BEING_CREATED);

  await context.dispatch('bind_current_timeline_references');

  if (!context.state.current_timeline) {
    context.commit(SET_404_ROUTE_ERROR);
    await context.dispatch('unset_current_timeline');
    return;
  }

  context.commit(SET_CURRENT_TIMELINE_IS_READY);

};

/**
 * @param {ActionContext} context
 */
actions.unset_current_timeline = (context) => {
  context.commit(CLEAR_CURRENT_TIMELINE_IS_READY);
  context.commit(CLEAR_CURRENT_TIMELINE_EVENTS_BEING_CREATED);
  context.dispatch('unbind_current_timeline_references');
};

/**
 * @param {ActionContext} context
 * @param {Partial<Workspace>} payload
 * @returns {Promise}
 */
actions.update_workspace = async (context, payload) => {

  if (!context.getters.firestore_refs.workspaces) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.workspaces, payload.id);

  return update_firestore_document({
    document: payload,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * @param {ActionContext} context
 * @param {Project} payload
 * @returns {Promise}
 */
actions.create_project = async (context, payload) => {

  const collection_ref = context.getters.firestore_refs.projects;

  const workspace_id = context.state.current_workspace_id;

  const project = await add_firestore_document({
    document: payload,
    collection_ref,
    user_id: context.state.user_id,
  }).promise;

  send_project_created_pub_sub_message({
    workspace_id,
    project_id: project.id,
  });

  return project;
};

/**
 * @param {ActionContext} context
 * @param {Partial<Project>} payload
 * @returns {Promise}
 */
actions.update_project = async (context, payload) => {

  if (!context.getters.firestore_refs.projects) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.projects, payload.id);

  return update_firestore_document({
    document: payload,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * @param {ActionContext} context
 * @param {Partial<MustUser>} payload
 * @returns {Promise}
 */
actions.update_current_user = (context, payload) => {

  const document_ref = doc(context.getters.firestore_refs.must_users, context.state.user_id);

  return update_firestore_document({
    document: payload,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {SequencedTimeline} payload.timeline
 * @returns {Promise}
 */
actions.create_new_timeline = async (context, payload) => {

  if (!context.getters.firestore_refs.timelines) {
    return Promise.reject();
  }

  // Create timeline, beats and default row in a batch
  let batch = writeBatch(db);

  // Ref for new timeline
  const timeline_ref = doc(context.getters.firestore_refs.timelines);

  const beat_collection = timeline_beat_refs.collection({
    workspace_id: context.state.current_workspace_id,
    project_id: context.state.current_project_id,
    timeline_id: timeline_ref.id,
    is_published: false,
  });

  // Create timeline beat.
  const beat_ref = doc(beat_collection);

  const beat_doc = {
    title: 'Intro',
    timeline_id: timeline_ref.id,
  };

  create_firestore_document({
    document: beat_doc,
    document_ref: beat_ref,
    batch,
    user_id: context.state.user_id,
  });

  // Create default initial timeline row
  const row = {
    title: initial_timeline_row_name,
    timeline_id: timeline_ref.id,
  };
  const row_collection = timeline_row_refs.collection({
    workspace_id: context.state.current_workspace_id,
    project_id: context.state.current_project_id,
    timeline_id: timeline_ref.id,
    is_published: false,
  });
  const row_ref = doc(row_collection);

  create_firestore_document({
    document: row,
    document_ref: row_ref,
    batch,
    user_id: context.state.user_id,
  });

  // Create default timeline event references
  const timeline_event_references = {
    explicit_timeline_event_references: {},
    events_in_threads: {},
    event_link_ids: {},
  };

  const timeline_event_references_ref = timeline_event_reference_refs.document({
    workspace_id: context.state.current_workspace_id,
    project_id: context.state.current_project_id,
    timeline_id: timeline_ref.id,
    is_published: false,
  });

  create_firestore_document({
    document: timeline_event_references,
    document_ref: timeline_event_references_ref,
    batch,
    user_id: context.state.user_id,
  });

  // Create timeline
  const timeline_doc = {
    ...payload.timeline,
    row_indexes: {
      [row_ref.id]: 0,
    },
    beat_ids_being_dragged: {},
    beat_step_indexes: {
      [beat_ref.id]: 0,
    },
    event_ids_being_dragged: {},
    event_row_ids: {},
    event_step_indexes: {},
    is_locked: false,
    progression_condition_ids_being_dragged: {},
    progression_condition_step_indexes: {},
    status: timeline_status_map.ACTIVE,
    project_id: context.state.current_project_id,
  };

  const full_timeline_doc = create_firestore_document({
    document: timeline_doc,
    document_ref: timeline_ref,
    batch,
    user_id: context.state.user_id,
  }).document;

  return batch.commit()
    .then(() => {
      return {
        id: timeline_ref.id,
        ...full_timeline_doc,
      };
    });
};

/**
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {Partial<SequencedTimeline>} param1.payload
 * @param {boolean} param1.is_published
 * @returns {Promise}
 */
actions.update_timeline = (context, {
  payload,
  is_published = false,
}) => {

  const timelines_collection = is_published ? context.getters.firestore_refs.published_timelines : context.getters.firestore_refs.timelines;

  if (!timelines_collection) {
    return Promise.reject();
  }

  const document_ref = doc(timelines_collection, payload.id);

  return update_firestore_document({
    document: payload,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * @param {ActionContext} context
 * @param {Partial<SequencedTimeline | PublishedTimeline>} payload
 * @returns {Promise}
 */
actions.update_current_timeline = (context, payload) => {
  return update_firestore_document({
    document: payload,
    document_ref: context.getters.firestore_refs.current.timeline,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Republishes a published timeline.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.timeline_id
 * @param {string} param1.beat_machine_sismic_yaml
 * @param {Object.<string, NarrativeStatecharts>} param1.narrative_statecharts_by_id
 * @param {Object.<string, NarrativeStatecharts>} param1.condition_statecharts_by_id
 * @param {Object.<string, NarrativeEvent>} param1.narrative_events_by_id
 * @param {Object.<string, HookContents>} param1.hook_contents_by_id
 * @returns {Promise<string>} Returns the ID of the new published timeline.
 */
actions.republish_timeline = async (context, {
  timeline_id,
  beat_machine_sismic_yaml,
  narrative_statecharts_by_id,
  condition_statecharts_by_id,
  narrative_events_by_id,
  hook_contents_by_id,
}) => {
  return context.dispatch('duplicate_timeline', {
    timeline_id,
    beat_machine_sismic_yaml,
    narrative_statecharts_by_id,
    condition_statecharts_by_id,
    narrative_events_by_id,
    hook_contents_by_id,
    is_republish: true,
  });
};

/**
 * Publishes a non-published timeline.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.timeline_id
 * @param {string} param1.beat_machine_sismic_yaml
 * @param {Object.<string, NarrativeStatecharts>} param1.narrative_statecharts_by_id
 * @param {Object.<string, NarrativeStatecharts>} param1.condition_statecharts_by_id
 * @param {Object.<string, NarrativeEvent>} param1.narrative_events_by_id
 * @param {Object.<string, HookContents>} param1.hook_contents_by_id
 * @returns {Promise<string>} Returns the ID of the new published timeline.
 */
actions.publish_timeline = async (context, {
  timeline_id,
  beat_machine_sismic_yaml,
  narrative_statecharts_by_id,
  condition_statecharts_by_id,
  narrative_events_by_id,
  hook_contents_by_id,
}) => {
  return context.dispatch('duplicate_timeline', {
    timeline_id,
    beat_machine_sismic_yaml,
    narrative_statecharts_by_id,
    condition_statecharts_by_id,
    narrative_events_by_id,
    hook_contents_by_id,
    is_publish: true,
  });
};

/**
 * Duplicates a timeline.
 *
 * If is_publish or is_republish is set, adds published_timeline fields and
 * duplicates to published_timelines collection. Also stores/replaces a copy
 * in the `unmodified_published_timelines` collection for the project.
 *
 * If is_unpublish is true, removes published_timeline fields.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.timeline_id
 * @param {string} [param1.title]
 * @param {string} [param1.beat_machine_sismic_yaml]
 * @param {Object.<string, NarrativeStatecharts>} [param1.narrative_statecharts_by_id]
 * @param {Object.<string, NarrativeStatecharts>} [param1.condition_statecharts_by_id]
 * @param {boolean} [param1.is_publish] true if we're publishing
 * @param {boolean} [param1.is_republish] true if we're re-publishing
 * @param {boolean} [param1.is_unpublish] true if we're un-publishing (i.e. copying a published timeline to draft).
 * @param {Object.<string, NarrativeEvent>} [param1.narrative_events_by_id]
 * @param {Object.<string, HookContents>} [param1.hook_contents_by_id]
 * @returns {Promise<string>} Returns the ID of the new published timeline.
 */
actions.duplicate_timeline = async (context, {
  timeline_id,
  title,
  beat_machine_sismic_yaml,
  narrative_statecharts_by_id = {},
  condition_statecharts_by_id = {},
  narrative_events_by_id = {},
  hook_contents_by_id = {},
  is_publish,
  is_republish,
  is_unpublish,
}) => {

  if ((is_publish || is_republish) && is_unpublish) {
    throw new Error('Cannot both publish/republish and unpublish a timeline');
  }

  const source_timeline_collection = is_republish || is_unpublish ? context.getters.firestore_refs.published_timelines : context.getters.firestore_refs.timelines;
  const source_is_published = is_republish || is_unpublish;

  if (!source_timeline_collection) {
    return Promise.reject();
  }

  // We'll capture these now as they may change before the transaction is run.
  const workspace_id = context.state.current_workspace_id;
  const project_id = context.state.current_project_id;

  // Set up some firestore references to use during our operations.
  const dest_timeline_collection_ref = is_publish || is_republish ? context.getters.firestore_refs.published_timelines : context.getters.firestore_refs.timelines;

  if (!dest_timeline_collection_ref) {
    return Promise.reject();
  }

  // First, fetch the timeline from firestore.
  const {
    timeline_data,
    timeline_event_references_data,
    child_collections,
  } = await fetch_timeline_from_firestore({
    db,
    workspace_id,
    project_id,
    timeline_id,
    is_published: !!source_is_published,
  });

  const new_timeline_doc_ref = is_republish ? doc(dest_timeline_collection_ref, timeline_id) : doc(dest_timeline_collection_ref);

  let new_timeline = {
    ...timeline_data,
    title: title || timeline_data.title,
    project_id,
    status: timeline_status_map.ACTIVE,
    // We don't want to bring across and in-progress dragging data.
    event_ids_being_dragged: {},
    beat_ids_being_dragged: {},
    progression_condition_ids_being_dragged: {},
  };

  if (is_publish || is_republish) {
    new_timeline = {
      ...new_timeline,
      sismic_yaml: beat_machine_sismic_yaml,
      publish_sync_state: timeline_publish_sync_status_map.IN_PROGRESS,
    };
  }

  if (is_unpublish) {
    if ('sismic_yaml' in new_timeline) {
      // @ts-ignore
      delete new_timeline.sismic_yaml;
    }
    if ('publish_sync_state' in new_timeline) {
      // @ts-ignore
      delete new_timeline.publish_sync_state;
    }
    if ('users_count' in new_timeline) {
      delete new_timeline.users_count;
    }
  }

  // Now copy the data across to the destination.
  await write_timeline_to_firestore({
    db,
    destination_timeline_ref: new_timeline_doc_ref,
    timeline_data: new_timeline,
    timeline_event_references_data,
    child_collections,
    narrative_statecharts_by_id,
    condition_statecharts_by_id,
    user_id: context.state.user_id,
  })
    .catch(async (err) => {

      if (is_republish) {
        // We can set the timeline publish status to FAILED
        await context.dispatch('update_failed_timeline_publish', {
          published_timeline_id: timeline_id,
        });
      }

      logger.error(err);
    });

  if (is_publish || is_republish) {
    const snapshot_collection = timeline_snapshot_refs.collection({
      workspace_id,
      project_id,
      timeline_id: new_timeline_doc_ref.id,
    });
    const snapshot_timeline_doc_ref = doc(snapshot_collection);

    /** @type {TimelineSnapshot} */
    const snapshot_timeline = {
      ...new_timeline,
      published_timeline_id: timeline_id,
      id: snapshot_timeline_doc_ref.id,
    };

    if ('sismic_yaml' in snapshot_timeline) {
      // @ts-ignore
      delete snapshot_timeline.sismic_yaml;
    }

    if ('publish_sync_state' in snapshot_timeline) {
      // @ts-ignore
      delete snapshot_timeline.publish_sync_state;
    }

    await write_timeline_to_firestore({
      db,
      destination_timeline_ref: snapshot_timeline_doc_ref,
      timeline_data: snapshot_timeline,
      timeline_event_references_data,
      child_collections,
      narrative_events_by_id,
      hook_contents_by_id,
      user_id: context.state.user_id,
    })
      .then(() => {
        // Try to invoke a API request which triggers a Pub/Sub message that asynchronously
        // copies the above published timeline data into the datastore.
        context.dispatch('send_publish_timeline_pub_sub_message', {
          published_timeline_id: new_timeline_doc_ref.id,
          workspace_id,
          project_id,
        });
      })
      .catch(async (err) => {
        // Make sure we don't leave the timeline sync state as IN_PROGRESS and instead
        // set to FAILED
        await context.dispatch('update_failed_timeline_publish', {
          published_timeline_id: new_timeline_doc_ref.id,
        });

        logger.error(err);
      });
  }

  return new_timeline_doc_ref.id;
};

/**
 * Sends a message (over REST API) notifying the platform services backend
 * that the user has asked us to publish/republish a timeline.
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.published_timeline_id
 * @param {string} param1.workspace_id
 * @param {string} param1.project_id
 * @returns {Promise}
 */
actions.send_publish_timeline_pub_sub_message = async (context, {
  published_timeline_id,
  workspace_id,
  project_id,
}) => {
  // try to invoke a API request which triggers a Pub/Sub message that asynchronously
  // copies the above published timeline data into the datastore
  try {
    await send_publish_timeline_pub_sub_message({
      workspace_id,
      project_id,
      published_timeline_id,
    });
  } catch (err) {
    logger.error(err);
    // Make sure we don't leave the timeline sync state as IN_PROGRESS and instead
    // set to FAILED
    return context.dispatch('update_failed_timeline_publish', {
      published_timeline_id,
    });
  }
};


/**
 * Sets the timeline publish sync state to failed
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.published_timeline_id
 * @returns {Promise}
 */
actions.update_failed_timeline_publish = async (context, {
  published_timeline_id,
}) => {

  if (!context.getters.firestore_refs.published_timelines) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.published_timelines, published_timeline_id);

  const document_with_updated_sync_state =  {
    publish_sync_state: timeline_publish_sync_status_map.FAILED,
  };

  return update_firestore_document({
    document: document_with_updated_sync_state,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Creates a new narrative event. If timeline_event_id is specified,
 * also sets timeline_event.narrative_event_id to the new narrative event's id.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {NarrativeEvent} param1.narrative_event
 * @param {string} param1.timeline_event_id
 * @returns {Promise}
 */
actions.create_narrative_event = async (context, {
  narrative_event,
  timeline_event_id,
}) => {

  if (!context.getters.firestore_refs.narrative_events || !context.getters.firestore_refs.timeline_events) {
    return Promise.reject();
  }

  let batch;

  const new_narrative_event_ref = doc(context.getters.firestore_refs.narrative_events);

  if (timeline_event_id) {

    batch = writeBatch(db);

    const timeline_event_ref = doc(context.getters.firestore_refs.timeline_events, timeline_event_id);

    update_firestore_document({
      document: {
        narrative_event_id: new_narrative_event_ref.id,
      },
      document_ref: timeline_event_ref,
      user_id: context.state.user_id,
      batch,
    });
  }

  const promise = create_firestore_document({
    document: {
      ...narrative_event,
    },
    document_ref: new_narrative_event_ref,
    user_id: context.state.user_id,
    batch,
  }).promise;

  if (batch) {
    return batch.commit();
  }

  return promise;
};

/**
 * @param {ActionContext} context
 * @param {Partial<NarrativeEvent>} narrative_event
 * @returns {Promise}
 */
actions.update_narrative_event = (context, narrative_event) => {

  if (!context.getters.firestore_refs.narrative_events) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.narrative_events, narrative_event.id);

  return update_firestore_document({
    document: narrative_event,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Updates a narrative event _without_ persisting the changes to the Firestore.
 *
 * @param {ActionContext} context
 * @param {Partial<NarrativeEvent>} narrative_event
 * @returns {Promise}
 */
actions.update_narrative_event_in_memory = (context, narrative_event) => {
  if (!narrative_event.id) return Promise.reject();
  context.commit(SET_NARRATIVE_EVENT, narrative_event);
  return Promise.resolve(context.state.project_narrative_events[narrative_event.id]);
};

/**
 * @param {ActionContext} context
 * @param {HookContents} hook_content
 * @returns {Promise}
 */
actions.attach_hook_content = (context, hook_content) => {

  if (!context.getters.firestore_refs.hook_contents) {
    return Promise.reject();
  }

  const collection_ref = context.getters.firestore_refs.hook_contents;

  return add_firestore_document({
    document: hook_content,
    collection_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * @param {ActionContext} context
 * @param {Partial<HookContents>} hook_content
 * @returns {Promise}
 */
actions.update_hook_content = (context, hook_content) => {

  if (!context.getters.firestore_refs.hook_contents) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.hook_contents, hook_content.id);

  return update_firestore_document({
    document: hook_content,
    document_ref,
    user_id: context.state.user_id,
  }).promise;
};

/**
 * @param {ActionContext} context
 * @param {string} id
 * @returns {Promise}
 */
actions.remove_hook_content = (context, id) => {

  if (!context.getters.firestore_refs.hook_contents) {
    return Promise.reject();
  }

  return deleteDoc(doc(context.getters.firestore_refs.hook_contents, id));
};

/**
 * @param {ActionContext} context
 * @param {Object} search
 * @param {string} search.workspace_id
 * @param {string} search.project_id
 * @param {string} search.provider
 * @param {string} search.q
 */
actions.search_content = async (context, search) => {
  return await content_search(
    search.workspace_id,
    search.project_id,
    search.provider,
    search.q);
};

/**
 * @param {ActionContext} context
 * @param {Object} search
 * @param {string} search.workspace_id
 * @param {string} search.project_id
 */
actions.get_content_providers_config = async (context, search) => {
  return await content_providers_config(
    search.workspace_id,
    search.project_id
  );
};

/**
 * @typedef {Object} CreateProjectApiKeyResponse
 * @property {string} title
 * @property {string} key
 * @property {string} type
 */

/**
 * Creates a project API key over API then updates the created firestore document
 * with any UI-only data.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.project_id
 * @param {string} param1.key_type
 * @param {string} param1.key_title
 * @returns {Promise<CreateProjectApiKeyResponse>}
 */
actions.create_project_api_key = async (context, {
  project_id,
  key_type,
  key_title,
}) => {

  // We're always in a workspace so context.state.current_workspace_id shall be set.
  const workspace_id = context.state.current_workspace_id;

  if (!context.getters.firestore_refs.current.workspace) {
    return Promise.reject();
  }

  // First we create the key over API. This shall create the firestore document
  // (including id, key, and type) under the returned id. If this fails, it should
  // throw an error.
  const {
    id,
    key,
  } = await create_api_key({
    workspace_id,
    project_id,
    key_type,
  });

  // Now we've got enough server-generated data to update the firestore document
  // with UI data.
  const api_key_doc_firestore_ref = api_key_refs.document({
    workspace_id,
    project_id,
    api_key_id: id,
  });

  await update_firestore_document({
    document_ref: api_key_doc_firestore_ref,
    document: {
      title: key_title,
    },
    user_id: context.state.user_id,
  }).promise;

  // We must return the key from this fn as, for secret keys, it won't be available in firestore.
  return {
    title: key_title,
    key,
    type: key_type,
  };
};

/**
 * Updates a project API key firestore document (UI-only data).
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.project_id
 * @param {string} param1.key_id
 * @param {string} param1.key_title
 * @returns {Promise}
 */
actions.update_project_api_key = async (context, {
  project_id,
  key_id,
  key_title,
}) => {

  // Get the reference to the project API key document
  const api_key_doc_firestore_ref = api_key_refs.document({
    workspace_id: context.state.current_workspace_id,
    project_id,
    api_key_id: key_id,
  });

  // Update with the given UI data and return a promise
  return update_firestore_document({
    document_ref: api_key_doc_firestore_ref,
    document: {
      title: key_title,
    },
    user_id: context.state.user_id,
  }).promise;
};

/**
 * Trigger a DELETE request to the backend where the key be deleted from Datastore and Firestore.
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {string} param2.workspace_id
 * @param {string} param2.project_id
 * @param {string} param2.project_api_key_id
 * @returns {Promise}
 */
actions.delete_project_api_key = (context, {
  workspace_id,
  project_id,
  project_api_key_id,
}) => {
  return delete_project_api_key({
    workspace_id,
    project_id,
    project_api_key_id,
  });
};

/**
 * Retrieve the analytics related to a timeline and store on the current_timeline_analytics state.
 * @param {ActionContext} context
 * @param {Object} param
 * @param {string} param.workspace_id
 * @param {string} param.project_id
 * @param {string} param.timeline_id
 *
 */
actions.get_timeline_analytics = async (context, {
  workspace_id,
  project_id,
  timeline_id,
}) => {
  const response = await get_analytics({
    workspace_id,
    project_id,
    timeline_id,
  });
  context.commit(SET_CURRENT_TIMELINE_ANALYTICS, response);
};

/**
 * @param {ActionContext} context
 * @param {boolean} is_completed
 *
 */
actions.set_simulation_beat_machine_completed = (context, is_completed) => {
  context.commit(SET_SIMULATION_BEAT_MACHINE_COMPLETED, is_completed);
};

/**
 * Creates a link for between two timeline events
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {String} param1.from_event_id the source event of the link
 * @param {String} param1.to_event_id the target event of the link
 * @param {String} [param1.colour] the colour of the link
 * @returns {Promise}
 */
actions.create_timeline_event_link = async (context, {
  from_event_id,
  to_event_id,
  colour,
}) => {

  if (!context.state.current_timeline || !context.state.current_timeline_event_references || !context.getters.firestore_refs.timeline_event_links || !context.getters.firestore_refs.timeline_linked_threads || !context.getters.firestore_refs.current.timeline_event_references) {
    return Promise.reject();
  }

  const composite_timeline_event_link_id = create_composite_id({
    ids: [
      from_event_id,
      to_event_id,
    ],
  });

  const batch = writeBatch(db);

  const timeline_event_link_ref = doc(context.getters.firestore_refs.timeline_event_links, composite_timeline_event_link_id);

  const timeline_event_link = {
    id: composite_timeline_event_link_id,
    from_event_id,
    to_event_id,
    colour: colour || null,
  };

  create_firestore_document({
    document: timeline_event_link,
    document_ref: timeline_event_link_ref,
    user_id: context.state.user_id,
    batch,
  });

  const timeline_event_references_ref = context.getters.firestore_refs.current.timeline_event_references;
  const timeline_event_references = context.state.current_timeline_event_references;

  /** @type {Object.<string, any>} */
  let timeline_event_reference_updates = {
    [`event_link_ids.${composite_timeline_event_link_id}`]: true,
  };

  update_firestore_document({
    document_ref: timeline_event_references_ref,
    document: timeline_event_reference_updates,
    user_id: context.state.user_id,
    batch,
  });

  const event_link_ids = {
    ...timeline_event_references.event_link_ids,
  };
  event_link_ids[composite_timeline_event_link_id] = true;

  regenerate_threads({
    event_link_ids,
    old_events_in_threads: timeline_event_references.events_in_threads,
    threads_collection_ref: context.getters.firestore_refs.timeline_linked_threads,
    timeline_event_references_ref,
    batch,
    user_id: context.state.user_id,
  });

  return batch.commit();
};

/**
 * Updates a link.
 *
 * @param {ActionContext} context
 * @param {Partial<TimelineEventLink> & Pick<TimelineEventLink, "id">} link_payload
 * @returns {Promise}
 */
actions.update_timeline_event_link = (context, link_payload) => {

  if (!context.getters.firestore_refs.timeline_event_links) {
    return Promise.reject();
  }

  const document_ref = doc(context.getters.firestore_refs.timeline_event_links, link_payload.id);

  return update_firestore_document({
    document_ref,
    document: link_payload,
    user_id: context.state.user_id,
  }).promise;
};


/**
 * Deletes a link between two timeline events
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.link_id The link to delete
 * @returns {Promise}
 */
actions.delete_timeline_event_link = async (context, {
  link_id,
}) => {

  if (!context.getters.firestore_refs.timeline_event_links || !context.getters.firestore_refs.current.timeline_event_references || !context.getters.firestore_refs.timeline_linked_threads) {
    return Promise.reject();
  }

  const timeline_event_references_ref = context.getters.firestore_refs.current.timeline_event_references;
  const timeline_link_doc = doc(context.getters.firestore_refs.timeline_event_links, link_id);
  const threads_collection_ref = context.getters.firestore_refs.timeline_linked_threads;

  return runTransaction(db, async (transaction) => {

    // Get up-to-date timeline event references as part of transaction
    const timeline_event_references_snapshot = await transaction.get(timeline_event_references_ref);
    const timeline_event_references = timeline_event_references_snapshot.data() || {
      event_link_ids: {},
      explicit_timeline_event_references: {},
      events_in_threads: {},
    };

    const write_promises = [];

    // Delete the timeline event link between the two events
    const delete_event_link_promise = transaction.delete(timeline_link_doc);
    write_promises.push(delete_event_link_promise);

    /** @type {Object.<string, any>} */
    let timeline_event_reference_updates = {
      [`event_link_ids.${link_id}`]: deleteField(),
    };

    // Update the timeline event references document
    const update_timeline_event_references_promise = update_firestore_document({
      document_ref: timeline_event_references_ref,
      document: timeline_event_reference_updates,
      user_id: context.state.user_id,
      transaction,
    }).promise;
    write_promises.push(update_timeline_event_references_promise);

    const event_link_ids = {
      ...timeline_event_references.event_link_ids,
    };
    delete event_link_ids[link_id];

    const thread_changes_promise = regenerate_threads({
      event_link_ids,
      old_events_in_threads: timeline_event_references.events_in_threads,
      threads_collection_ref,
      timeline_event_references_ref,
      transaction,
      user_id: context.state.user_id,
    });

    write_promises.push(thread_changes_promise);

    return Promise.all([
      ...write_promises,
      thread_changes_promise,
    ]);
  });
};

/**
 * Replaces a link between two timeline events with another.
 *
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.remove_link_id The link id to delete
 * @param {string} param1.add_from_event_id The from event ID
 * @param {string} param1.add_to_event_id The to event ID
 * @returns {Promise}
 */
actions.change_timeline_event_link = async (context, {
  remove_link_id,
  add_from_event_id,
  add_to_event_id,
}) => {

  if (!context.getters.firestore_refs.timeline_event_links || !context.getters.firestore_refs.current.timeline_event_references || !context.getters.firestore_refs.timeline_linked_threads) {
    return Promise.reject();
  }

  const composite_timeline_event_link_id = create_composite_id({
    ids: [
      add_from_event_id,
      add_to_event_id,
    ],
  });

  const timeline_event_references_ref = context.getters.firestore_refs.current.timeline_event_references;
  const remove_timeline_link_doc = doc(context.getters.firestore_refs.timeline_event_links, remove_link_id);
  const add_timeline_link_doc = doc(context.getters.firestore_refs.timeline_event_links, composite_timeline_event_link_id);
  const threads_collection_ref = context.getters.firestore_refs.timeline_linked_threads;

  return runTransaction(db, async (transaction) => {

    // Get up-to-date timeline event references as part of transaction
    const timeline_event_references_snapshot = await transaction.get(timeline_event_references_ref);
    const timeline_event_references = timeline_event_references_snapshot.data() || {
      event_link_ids: {},
      explicit_timeline_event_references: {},
      events_in_threads: {},
    };
    const event_link_ids = {
      ...timeline_event_references.event_link_ids,
    };

    const write_promises = [];

    // Delete the timeline event link between the two events
    const delete_event_link_promise = transaction.delete(remove_timeline_link_doc);
    write_promises.push(delete_event_link_promise);

    /** @type {Object.<string, any>} */
    let timeline_event_reference_updates = {
      [`event_link_ids.${remove_link_id}`]: deleteField(),
    };

    delete event_link_ids[remove_link_id];

    const timeline_event_link = {
      id: composite_timeline_event_link_id,
      from_event_id: add_from_event_id,
      to_event_id: add_to_event_id,
      colour: null,
    };

    const create_link_promise = create_firestore_document({
      document: timeline_event_link,
      document_ref: add_timeline_link_doc,
      user_id: context.state.user_id,
      transaction,
    }).promise;

    write_promises.push(create_link_promise);

    timeline_event_reference_updates[`event_link_ids.${composite_timeline_event_link_id}`] =  true;

    event_link_ids[composite_timeline_event_link_id] = true;

    // Update the timeline event references document
    const update_timeline_event_references_promise = update_firestore_document({
      document_ref: timeline_event_references_ref,
      document: timeline_event_reference_updates,
      user_id: context.state.user_id,
      transaction,
    }).promise;
    write_promises.push(update_timeline_event_references_promise);

    const thread_changes_promise = regenerate_threads({
      event_link_ids,
      old_events_in_threads: timeline_event_references.events_in_threads,
      threads_collection_ref,
      timeline_event_references_ref,
      transaction,
      user_id: context.state.user_id,
    });

    write_promises.push(thread_changes_promise);

    return Promise.all([
      ...write_promises,
      thread_changes_promise,
    ]);
  });
};

/**
 * Deletes timeline event variable change at index.
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.id
 * @param {string} param1.document_type
 * @param {StatefulVariableChange} param1.variable_change
 * @returns {Promise}
 */
actions.delete_variable_change = (context, {
  id,
  document_type,
  variable_change,
}) => {

  let document_ref;

  if (document_type === 'LINK') {
    if (!context.getters.firestore_refs.timeline_event_links) {
      return Promise.reject();
    }
    document_ref = doc(context.getters.firestore_refs.timeline_event_links, id);
  } else {
    if (!context.getters.firestore_refs.timeline_events) {
      return Promise.reject();
    }
    document_ref = doc(context.getters.firestore_refs.timeline_events, id);
  }

  return update_firestore_document({
    document_ref,
    document: {
      variable_changes: arrayRemove(variable_change),
    },
    user_id: context.state.user_id,
  }).promise;
};


/**
 * Replaces old_variable_change with new_variable_change.
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.id
 * @param {string} param1.document_type
 * @param {StatefulVariableChange | VariableChange} [param1.old_variable_change]
 * @param {StatefulVariableChange | VariableChange} param1.new_variable_change
 * @returns {Promise}
 */
actions.update_variable_change = (context, {
  id,
  document_type,
  old_variable_change,
  new_variable_change,
}) => {

  const batch = writeBatch(db);

  let document_ref;

  if (document_type === 'LINK') {
    if (!context.getters.firestore_refs.timeline_event_links) {
      return Promise.reject();
    }
    document_ref = doc(context.getters.firestore_refs.timeline_event_links, id);
  } else {
    if (!context.getters.firestore_refs.timeline_events) {
      return Promise.reject();
    }
    document_ref = doc(context.getters.firestore_refs.timeline_events, id);
  }

  if (old_variable_change) {
    update_firestore_document({
      document_ref,
      document: {
        variable_changes: arrayRemove(old_variable_change),
      },
      user_id: context.state.user_id,
      batch,
    });
  }

  update_firestore_document({
    document_ref,
    document: {
      variable_changes: arrayUnion(new_variable_change),
    },
    user_id: context.state.user_id,
    batch,
  });

  return batch.commit();
};


/**
 * Create timeline variable
 * @param {ActionContext} context
 * @param {TimelineVariable} timeline_variable
 * @returns {Promise}
 */
actions.create_timeline_variable = (context, timeline_variable) => {


  return update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: {
      [`variables.${timeline_variable.name}`]: timeline_variable,
    },
    user_id: context.state.user_id,
  }).promise;
};


/**
 * Delete a timeline variable and update related entites that reference them.
 *
 * - Timeline event StatefulVariableChange entries
 * - Progression condition VariableRule entries
 *
 * @param {ActionContext} context
 * @param {Object} param2
 * @param {string} param2.variable_id
 * @returns {Promise}
 */
actions.delete_timeline_variable = (context, {
  variable_id,
}) => {

  if (!context.getters.firestore_refs.current.timeline || !context.getters.firestore_refs.timeline_events || !context.getters.firestore_refs.timeline_progression_conditions || !context.getters.firestore_refs.timeline_event_links || !context.getters.firestore_refs.timeline_push_events) {
    return Promise.reject();
  }

  const batch = writeBatch(db);

  // First delete any variable_change entries for any timeline events/links that use
  // the variable we will delete.
  [
    {
      state_prop: 'current_timeline_events',
      coll: context.getters.firestore_refs.timeline_events,
    },
    {
      state_prop: 'current_timeline_event_links',
      coll: context.getters.firestore_refs.timeline_event_links,
    },
  ].forEach(({
    state_prop,
    coll,
  }) => {
    Object.values(context.state[state_prop]).forEach(/** @param {TimelineEvent | TimelineEventLink} document */(document) => {

      let variable_changes = document?.variable_changes || [];

      let variable_changes_to_remove = variable_changes.filter(c => c.variable_id === variable_id);

      if (variable_changes_to_remove.length && coll) {

        // @ts-ignore
        const document_ref = doc(coll, document.id);

        update_firestore_document({
          user_id: context.state.user_id,
          document_ref,
          document: {
            variable_changes: arrayRemove(...variable_changes_to_remove),
          },
          batch,
        });
      }
    });
  });

  // Delete variable rules from progression conditions & links.
  [
    [
      'current_timeline_progression_conditions',
      context.getters.firestore_refs.timeline_progression_conditions,
    ],
    [
      'current_timeline_event_links',
      context.getters.firestore_refs.timeline_event_links,
    ],
  ].forEach(([
    state_prop,
    collection,
  ]) => {

    // Delete variable rules that reference this
    // variable in a variable rule condition.
    Object.values(context.state[state_prop])
      .forEach(/** @param {TimelineEventLink | TimelineProgressionCondition} cond */(cond) => {

        const changes = {};

        if (cond.rules) {
          const rule_ids_to_remove = [];
          Object.keys(cond.rules).forEach((rule_id) => {
            const rule = cond.rules?.[rule_id];
            if (!rule) {
              return;
            }
            // Remove any relevant variable rules.
            const has_relevant_var_rule = !!rule.variable_rules?.find(rule => rule.variable_id == variable_id);
            // Remove any relevant time-based variable rules.
            const has_relevant_time_condition_rule = rule.real_time_variable === variable_id;

            if (has_relevant_var_rule || has_relevant_time_condition_rule) {
              rule_ids_to_remove.push(rule_id);
            }
          });

          if (rule_ids_to_remove.length) {
            rule_ids_to_remove.forEach((rule_id) => changes[`rules.${rule_id}`] = deleteField());
            changes.rules_order = arrayRemove(...rule_ids_to_remove);
          }
        }

        if (Object.keys(changes).length) {

          // @ts-ignore
          const document_ref = doc(collection, cond.id);

          update_firestore_document({
            document_ref,
            document: changes,
            user_id: context.state.user_id,
            batch,
          });
        }
      });
  });

  // Delete push events that reference this
  // variable in a time condition.
  Object.values(context.state.current_timeline_push_events)
    .filter(cond => {
      return cond.real_time_variable === variable_id;
    })
    .forEach(cond => {
      // @ts-ignore
      const document_ref = doc(context.getters.firestore_refs.timeline_push_events, cond.id);
      batch.delete(document_ref);
    });

  // Next delete the variable definition itself
  update_firestore_document({
    document_ref: context.getters.firestore_refs.current.timeline,
    document: {
      [`variables.${variable_id}`]: deleteField(),
    },
    user_id: context.state.user_id,
    batch,
  });

  return batch.commit();
};


/**
 * Create timeline variable
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {Omit<TimelinePushEvent, "id">} param1.timeline_push_event
 * @returns {Promise}
 */
actions.create_timeline_push_event = (context, {
  timeline_push_event,
}) => {

  return add_firestore_document({
    collection_ref: context.getters.firestore_refs.timeline_push_events,
    document: {
      ...timeline_push_event,
    },
    user_id: context.state.user_id,
  }).promise;
};


/**
 * Create timeline variable
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {TimelinePushEvent} param1.timeline_push_event
 * @returns {Promise}
 */
actions.update_timeline_push_event = (context, {
  timeline_push_event,
}) => {

  if (!context.getters.firestore_refs.timeline_push_events) {
    return Promise.reject();
  }

  return update_firestore_document({
    document_ref: doc(context.getters.firestore_refs.timeline_push_events, timeline_push_event.id),
    document: {
      ...timeline_push_event,
    },
    user_id: context.state.user_id,
  }).promise;
};


/**
 * Delete timeline variable
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {string} param1.document_id
 * @returns {Promise}
 */
actions.delete_timeline_push_event = (context, {
  document_id,
}) => {

  if (!context.getters.firestore_refs.timeline_push_events) {
    return Promise.reject();
  }

  return deleteDoc(doc(context.getters.firestore_refs.timeline_push_events, document_id));
};


/**
 * Delete timeline variable
 * @param {ActionContext} context
 * @param {Object} param1
 * @param {OpenAITimelineData|null} param1.openai_timeline_data
 * @param {number|undefined} param1.total_tokens
 * @returns {Promise}
 */
actions.create_ai_timeline = async (context, {
  openai_timeline_data = null,
  total_tokens,
}) => {

  if (!context.getters.firestore_refs.timeline_linked_threads || !context.getters.firestore_refs.current.timeline_event_references) {
    return;
  }

  console.log('create_ai_timeline');
  openai_timeline_data = openai_timeline_data;

  if (openai_timeline_data === null) {
    return;
  }

  /** @type {Record.<string, string>} */
  const event_name_to_timeline_event_id = {};
  const timeline_changes = {
    total_tokens,
  };
  const timeline_event_reference_updates = {};
  /** @type {Record.<string, boolean>} */
  const event_link_ids = {};
  const batch = writeBatch(db);

  const document_data = convert_openai_schema_to_timeline({
    openai_timeline_data,
    timeline_id: context.state.current_timeline_id,
  });

  /**
   * @param {typeof document_data.events[0]} param0
   */
  function create_events({
    timeline_event,
    narrative_event,
    step_index,
    row_index,
  }) {

    if (!context.getters.firestore_refs.narrative_events || !context.getters.firestore_refs.timeline_events || !context.state.current_timeline || !context.getters.firestore_refs.timeline_rows) {
      return;
    }

    const new_narrative_event_ref = doc(context.getters.firestore_refs.narrative_events);
    create_firestore_document({
      document: {
        ...narrative_event,
      },
      document_ref: new_narrative_event_ref,
      user_id: context.state.user_id,
      batch,
    });

    // Ensure we map to the new narrative event ID.
    timeline_event.narrative_event_id = new_narrative_event_ref.id;

    const new_timeline_event_ref = doc(context.getters.firestore_refs.timeline_events);

    const event_id = new_timeline_event_ref.id;

    create_firestore_document({
      batch,
      document_ref: new_timeline_event_ref,
      document: timeline_event,
      user_id: context.state.user_id,
    }).document;

    timeline_changes[`event_step_indexes.${event_id}`] = step_index;

    // We may already have a row at this step index.
    let row_id = Object.keys(context.state.current_timeline.row_indexes)
      .find((id) => {
        return context.state.current_timeline?.row_indexes[id] === row_index;
      });

    if (!row_id) {

      // A row does not exist at this row_index so we must create one.
      let new_row_ref = doc(context.getters.firestore_refs.timeline_rows);

      row_id = new_row_ref.id;

      create_firestore_document({
        batch,
        document_ref: new_row_ref,
        document: {
          title: '',
          timeline_id: context.state.current_timeline_id,
        },
        user_id: context.state.user_id,
      });

      timeline_changes[`row_indexes.${row_id}`] = row_index;
    }

    timeline_changes[`event_row_ids.${event_id}`] = row_id;

    event_name_to_timeline_event_id[narrative_event.title] = event_id;
  }

  /**
   * @param {typeof document_data.links[0]} param0
   */
  function create_link({
    link,
    from_event_name,
    to_event_name,
  }) {

    if (!context.getters.firestore_refs.timeline_event_links) {
      return;
    }

    const from_event_id = event_name_to_timeline_event_id[from_event_name];
    const to_event_id = event_name_to_timeline_event_id[to_event_name];

    const composite_timeline_event_link_id = create_composite_id({
      ids: [
        from_event_id,
        to_event_id,
      ],
    });

    const timeline_event_link_ref = doc(context.getters.firestore_refs.timeline_event_links, composite_timeline_event_link_id);

    /** @type { TimelineEventLink } */
    const timeline_event_link = {
      ...link,
      id: composite_timeline_event_link_id,
      from_event_id,
      to_event_id,
    };

    create_firestore_document({
      document: timeline_event_link,
      document_ref: timeline_event_link_ref,
      user_id: context.state.user_id,
      batch,
    });

    event_link_ids[composite_timeline_event_link_id] = true;

    /** @type {Object.<string, any>} */
    timeline_event_reference_updates[`event_link_ids.${composite_timeline_event_link_id}`] = true;
  }

  document_data.variables.forEach((timeline_variable) => {
    timeline_changes[`variables.${timeline_variable.name}`] = timeline_variable;
  });

  document_data.events.map((events) => create_events(events));
  document_data.links.map((link) => create_link(link));

  update_firestore_document({
    batch,
    document_ref: context.getters.firestore_refs.current.timeline,
    document: timeline_changes,
    user_id: context.state.user_id,
  });

  const timeline_event_references_ref = context.getters.firestore_refs.current.timeline_event_references;
  update_firestore_document({
    document_ref: timeline_event_references_ref,
    document: timeline_event_reference_updates,
    user_id: context.state.user_id,
    batch,
  });

  regenerate_threads({
    event_link_ids,
    old_events_in_threads: {},
    threads_collection_ref: context.getters.firestore_refs.timeline_linked_threads,
    timeline_event_references_ref,
    batch,
    user_id: context.state.user_id,
  });

  await batch.commit();

  return;
};


/**
 * Update workspace member over API.
 * @param {ActionContext} context
 * @param {Parameters<create_workspace_membership>[0]} params
 * @returns {ReturnType<create_workspace_membership>}
 */
actions.create_workspace_membership = (context, params) => {
  return create_workspace_membership(params);
};


/**
 * Update workspace member over API.
 * @param {ActionContext} context
 * @param {Parameters<update_workspace_membership>[0]} params
 * @returns {ReturnType<update_workspace_membership>}
 */
actions.update_workspace_membership = (context, params) => {
  return update_workspace_membership(params);
};


/**
 * Delete workspace member over API.
 * @param {ActionContext} context
 * @param {Parameters<delete_workspace_membership>[0]} params
 * @returns {ReturnType<delete_workspace_membership>}
 */
actions.delete_workspace_membership = (context, params) => {
  return delete_workspace_membership(params);
};


/**
 * Apends to timeline's ai_chat_messages.
 * @param {ActionContext} context
 * @param {Object} param0
 * @param {{content: string, role: string, name?: string}[]} param0.messages
 * @returns {Promise | undefined}
 */
actions.add_timeline_ai_messages = (context, {
  messages,
}) => {

  const timeline_ref = context.getters.firestore_refs.current.timeline;
  const current_timeline = context.state.current_timeline;

  if (!timeline_ref || !current_timeline) {
    return;
  }

  const timeline_changes = {
    ai_chat_messages: current_timeline.ai_chat_messages ? arrayUnion(...messages) : [
      ...messages,
    ],
  };

  return update_firestore_document({
    document_ref: timeline_ref,
    document: timeline_changes,
    user_id: context.state.user_id,
  }).promise;
};


/**
 * Overwrites timeline's ai_chat_messages.
 * @param {ActionContext} context
 * @param {Object} param0
 * @param {{content: string, role: string,}[]} param0.messages
 * @returns {Promise | undefined}
 */
actions.set_timeline_ai_messages = (context, {
  messages,
}) => {

  const timeline_ref = context.getters.firestore_refs.current.timeline;
  const current_timeline = context.state.current_timeline;

  if (!timeline_ref || !current_timeline) {
    return;
  }

  const timeline_changes = {
    ai_chat_messages: messages,
  };

  return update_firestore_document({
    document_ref: timeline_ref,
    document: timeline_changes,
    user_id: context.state.user_id,
  }).promise;
};


/**
 * Removes all events, links, rows & threads from a timeline.
 *
 * @param {ActionContext} context
 * @returns {Promise}
 */
actions.empty_timeline = async (context) => {

  // We're going to delete all timeline_events, timeline_threads, timeline_event_links, timeline_rows on this timeline.
  const timeline_ref = context.getters.firestore_refs.current.timeline;
  const timeline_event_references_ref = context.getters.firestore_refs.current.timeline_event_references;

  const events_collection_ref = context.getters.firestore_refs.timeline_events;
  const threads_collection_ref = context.getters.firestore_refs.timeline_linked_threads;
  const event_links_ref = context.getters.firestore_refs.timeline_event_links;
  const rows_collection_ref = context.getters.firestore_refs.timeline_rows;

  if (!timeline_ref || !timeline_event_references_ref || !events_collection_ref || !event_links_ref || !threads_collection_ref || !rows_collection_ref) {
    return Promise.reject();
  }

  return runTransaction(db, async (transaction) => {
    const write_promises = [];
    const [
      timeline_doc,
      references_doc,
    ] = await Promise.all([
      transaction.get(timeline_ref),
      transaction.get(timeline_event_references_ref),
    ]);

    if (!timeline_doc.exists() || !references_doc.exists()) {
      // Either the row or the timeline has been deleted, so we bail.
      return;
    }

    const timeline_data = timeline_doc.data();
    const references_data = references_doc.data();

    const event_ids = Object.keys(timeline_data.event_step_indexes);
    const row_ids = Object.keys(timeline_data.row_indexes);
    const link_ids = Object.keys(references_data.event_link_ids);
    const thread_ids = Object.keys(references_data.events_in_threads);

    const references_changes = {
      event_link_ids: {},
      events_in_threads: {},
    };

    const timeline_changes = {
      event_step_indexes: {},
      event_row_ids: {},
      row_indexes: {},
      variables: {},
    };

    write_promises.push(transaction.update(timeline_ref, timeline_changes));
    write_promises.push(transaction.update(timeline_event_references_ref, references_changes));
    event_ids.forEach((id) => write_promises.push(transaction.delete(doc(events_collection_ref, id))));
    link_ids.forEach((id) => write_promises.push(transaction.delete(doc(event_links_ref, id))));
    thread_ids.forEach((id) => write_promises.push(transaction.delete(doc(threads_collection_ref, id))));
    row_ids.forEach((id) => write_promises.push(transaction.delete(doc(rows_collection_ref, id))));
    return Promise.all(write_promises);
  });
};

/** @typedef {typeof actions} Actions */

export default actions;
